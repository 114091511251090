import { NetworkApi } from 'network';
import {
	AccessContent,
	AccessDto,
	AccessFilters,
	AccessListContent,
	AccessListDetail,
} from 'types/Access';
import { List } from 'types/List';

export class AccessService extends NetworkApi {
	public static getAccess(userId: string, filters?: AccessFilters) {
		return this.get<List<AccessContent>, AccessFilters>(
			`/users/${userId}/access`,
			filters,
		);
	}

	public static getAccessList(filters?: AccessFilters) {
		return this.get<List<AccessListContent>, AccessFilters>(
			`/users/access`,
			filters,
		);
	}

	public static getAccessDetails() {
		return this.get<AccessListDetail[]>(`/users/me/access/details`);
	}

	public static createAccess(userId: string, accessDto: AccessDto) {
		return this.put<void, AccessDto, void>(
			`/users/${userId}/access`,
			accessDto,
		);
	}

	public static async deleteAccess(userId: string, accessId: string) {
		return this.delete<void>(`/users/${userId}/access/${accessId}`);
	}

	public static async deleteMultiplesAccess(
		userId: string,
		accessIds: string[],
	) {
		return Promise.all(
			accessIds.map(accessId => this.deleteAccess(userId, accessId)),
		);
	}
}
