import * as React from 'react';

const Currency: React.FC<{ color?: string }> = ({ color }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={14} height={16} fill="none">
		<path
			fill={color ?? '#7664FA'}
			d="M10.75 11h1.5V2h-7.5v1.5h6V11Zm0 1.5v2.25c0 .414-.338.75-.755.75h-8.99a.75.75 0 0 1-.755-.75l.002-10.5c0-.414.338-.75.756-.75H3.25V1.25A.75.75 0 0 1 4 .5h9a.75.75 0 0 1 .75.75v10.5a.75.75 0 0 1-.75.75h-2.25ZM1.752 5l-.002 9h7.5V5H1.752Zm1.498 6h3.375a.375.375 0 1 0 0-.75h-2.25a1.875 1.875 0 1 1 0-3.75h.375v-.75h1.5v.75h1.5V8H4.375a.375.375 0 0 0 0 .75h2.25a1.875 1.875 0 1 1 0 3.75H6.25v.75h-1.5v-.75h-1.5V11Z"
		/>
	</svg>
);
export default Currency;
