import { Account } from 'types/Account';
import { useEffect, useMemo, useState } from 'react';
import PostingCreationListForm from './PostingCreationListForm';
import { PaymentType, PostingDto } from 'types/Posting';
import PostingCreationToken from './PostingCreationToken';
import PostingCreationConfirmation from './PostingCreationConfirmation';
import PostingCreationSuccess from './PostingCreationSuccess';
import PostingCreationReceipt from './PostingCreationReceipt';
import { notification } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { PostingsService } from 'modules/escrow/services';
import { Modal } from 'components/Modal';
import PostingCreationTedConfirmation from './PostingCreationTedConfirmation';
import {
	AutoApprovePostingModal,
	AutoApprovePostingResultModal,
} from '../AutoApprovePostingModal';
import dayjs from 'dayjs';
import { AutoApprovePostingSucessModalType } from '../AutoApprovePostingModal/AutoApprovePostingResultModal';
import DestinationCreation from './DestinationCreation';
import PostingCreationDataForm from './PostingCreationDataForm';

export type PostingDtoForm = Partial<PostingDto>;

enum PostingSteps {
	LIST = 'LIST',
	FORM = 'FORM',
	CREATE_BENEFICIARY = 'CREATE_BENEFICIARY',
	TOKEN = 'TOKEN',
	CONFIRMATION_TED = 'CONFIRMATION_TED',
	CONFIRMATION = 'CONFIRMATION',
	SUCCESS = 'SUCCESS',
	RECEIPT = 'RECEIPT',
}

interface IPostingCreationProps {
	isOpen: boolean;
	account: Account;
	onClose: () => void;
}
const PostingCreation = ({
	isOpen,
	account,
	onClose,
}: IPostingCreationProps) => {
	const [api, contextHolder] = notification.useNotification();

	const [step, setStep] = useState<PostingSteps>(PostingSteps.LIST);
	const [postingDto, setPostingDto] = useState<PostingDtoForm>();
	const [transactionId, setTransactionId] = useState<string | null>(null);
	const [isAutomaticallyApproved, setIsAutomaticallyApproved] =
		useState(false);
	const [autoApproveModalOpen, setAutoApproveModalOpen] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState<string>('');
	const [showTimeoutModal, setShowTimeoutModal] = useState(false);

	const { mutate, isPending } = useMutation<
		{ id: string },
		ApiError,
		{ postingDto: PostingDto; mfaToken: string }
	>({
		mutationFn: ({ postingDto, mfaToken }) => {
			return PostingsService.createPosting(
				{
					...postingDto,
					...(isAutomaticallyApproved && {
						automaticallyApprove: true,
					}),
				},
				mfaToken,
			);
		},
		onSuccess: ({ id }) => {
			setTransactionId(id);
			//posting scheduled for today
			if (
				isAutomaticallyApproved &&
				postingDto?.type === PaymentType.PIX &&
				postingDto?.scheduled_date === dayjs().format('YYYY-MM-DD')
			) {
				setIsAutomaticallyApproved(false);
				setAutoApproveModalOpen(true);
				onClose();
				return;
			}

			setIsAutomaticallyApproved(false);
			setStep(PostingSteps.SUCCESS);
		},
		onError: e => {
			api.error({
				description: e?.data?.message,
				message: 'Ocorreu um problema ao solicitar a transferência.',
			});
		},
	});

	useEffect(() => {
		if (isOpen) {
			setStep(PostingSteps.LIST);
		}

		return () => {
			setIsAutomaticallyApproved(false);
		};
	}, [isOpen]);

	const title = useMemo(() => {
		if (step === PostingSteps.SUCCESS)
			return 'Solicitação realizada com sucesso!';
		if (step === PostingSteps.CONFIRMATION_TED) return 'Atenção!';
		return 'Solicitar Transferência';
	}, [step]);

	if (step === PostingSteps.RECEIPT && postingDto && transactionId)
		return (
			<PostingCreationReceipt
				isOpen={isOpen}
				account={account}
				postingDto={postingDto}
				transactionId={transactionId}
				onClose={onClose}
			/>
		);

	return (
		<>
			{contextHolder}
			<Modal title={title} width={520} isOpen={isOpen} onClose={onClose}>
				<div
					style={{
						display: 'flex',
						width: '100%',
						flexDirection: 'column',
						minHeight: ![
							PostingSteps.TOKEN,
							PostingSteps.SUCCESS,
							PostingSteps.CONFIRMATION_TED,
						].includes(step)
							? '630px'
							: 'auto',
					}}
				>
					{step === PostingSteps.LIST && (
						<PostingCreationListForm
							account={account}
							onCreateBeneficiary={() =>
								setStep(PostingSteps.CREATE_BENEFICIARY)
							}
							onNext={posting => {
								setPostingDto(state => ({
									...state,
									account_destination_id: posting!,
									account_id: account.id,
								}));
								setStep(PostingSteps.FORM);
							}}
						/>
					)}
					{step === PostingSteps.CREATE_BENEFICIARY && (
						<DestinationCreation
							account={account}
							onCreate={newDestinationId => {
								setPostingDto(state => ({
									...state,
									account_destination_id: newDestinationId,
									account_id: account.id,
								}));

								setStep(PostingSteps.FORM);
							}}
							onBack={() => setStep(PostingSteps.LIST)}
							isLoading={false}
						/>
					)}

					{step === PostingSteps.FORM && (
						<PostingCreationDataForm
							destination={
								account.destinations.find(
									dest =>
										dest.id ===
										postingDto?.account_destination_id,
								)!
							}
							account={account}
							onBack={() => setStep(PostingSteps.LIST)}
							onNext={posting => {
								setPostingDto(state => {
									return {
										...state,
										...posting,
									};
								});
								setStep(PostingSteps.CONFIRMATION);
							}}
						/>
					)}

					{step === PostingSteps.CONFIRMATION && postingDto && (
						<PostingCreationConfirmation
							isLoading={isPending}
							posting={postingDto}
							account={account}
							onClose={onClose}
							onConfirm={(description, isApproved) => {
								if (isApproved) {
									setIsAutomaticallyApproved(true);
								}
								setPostingDto({
									...postingDto,
									description,
								});
								if (postingDto.type === PaymentType.TED) {
									setStep(PostingSteps.CONFIRMATION_TED);
									return;
								}
								setStep(PostingSteps.TOKEN);
							}}
						/>
					)}
					{step === PostingSteps.CONFIRMATION_TED && postingDto && (
						<PostingCreationTedConfirmation
							onClose={onClose}
							onContinuePix={() => {
								setPostingDto({
									...postingDto,
									type: PaymentType.PIX,
								});
								setStep(PostingSteps.TOKEN);
							}}
							onContinue={() => setStep(PostingSteps.TOKEN)}
						/>
					)}
					{step === PostingSteps.TOKEN && postingDto && (
						<PostingCreationToken
							isLoading={isPending}
							onClose={onClose}
							onConfirm={mfaToken => {
								mutate({
									postingDto: postingDto as PostingDto,
									mfaToken,
								});
							}}
						/>
					)}
					{step === PostingSteps.SUCCESS && (
						<PostingCreationSuccess
							onClose={onClose}
							onReceipt={() => setStep(PostingSteps.RECEIPT)}
						/>
					)}
				</div>
			</Modal>
			<AutoApprovePostingModal
				isOpen={autoApproveModalOpen}
				onClose={() => {
					setAutoApproveModalOpen(false);
					setIsAutomaticallyApproved(false);
					onClose();
				}}
				postingId={transactionId ?? ''}
				onFinish={(success, message) => {
					setAutoApproveModalOpen(false);
					setIsAutomaticallyApproved(false);
					onClose();

					if (success) {
						setShowSuccessModal(true);
					}
					if (success === undefined) {
						setShowTimeoutModal(true);
					}
					if (success === false) {
						setShowErrorModal(message ?? '');
					}
				}}
			/>
			<AutoApprovePostingResultModal
				isOpen={showSuccessModal}
				onClose={() => setShowSuccessModal(false)}
				modalType={AutoApprovePostingSucessModalType.SUCCESS}
			/>
			<AutoApprovePostingResultModal
				isOpen={!!showErrorModal}
				onClose={() => setShowErrorModal('')}
				modalType={AutoApprovePostingSucessModalType.ERROR}
				textMessage={showErrorModal}
			/>
			<AutoApprovePostingResultModal
				isOpen={showTimeoutModal}
				onClose={() => setShowTimeoutModal(false)}
				modalType={AutoApprovePostingSucessModalType.TIMEOUT}
			/>
		</>
	);
};

export default PostingCreation;
