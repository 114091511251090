import { useForm } from 'antd/es/form/Form';
import { Destination } from 'types/Destination';

import { Modal } from 'components/Modal';
import { useTheme } from 'styled-components';

import { useEffect, useState } from 'react';

import DestinationCreationForm from './DestinationCreationForm';
import DestinationReviewModal from './DestinationReviewModal';
import { PixKey } from 'components/PixKeySelect/PixKeySelect';
import { notification } from 'antd';

interface IDestinationCreationModalProps {
	isOpen: boolean;
	isLoading?: boolean;
	destinations?: Destination[];
	accountId?: string;
	onClose: () => void;
	onCreateOrUpdate: (destination: Destination) => void;
	onDelete: (destinationId: string) => void;
}

const DestinationCreationModal = ({
	accountId,
	destinations,
	isLoading,
	isOpen,
	onClose,
	onCreateOrUpdate,
	onDelete,
}: IDestinationCreationModalProps) => {
	const theme = useTheme();
	const [form] = useForm();
	const [_, contextHolder] = notification.useNotification();

	const [selectedDestination, setSelectedDestination] =
		useState<Destination>();
	const [destinationData, setDestinationData] = useState<Destination>();
	const [destinationReviewModalOpen, setDestinationReviewModalOpen] =
		useState(false);
	const [selectedPixKey, setSelectedPixKey] = useState<PixKey>(
		PixKey.TAXPAYER_ID,
	);

	useEffect(() => {
		if (!selectedDestination) {
			form.resetFields();
		}
	}, [selectedDestination]);

	return (
		<>
			{contextHolder}
			<Modal
				title="Adicionar Conta Beneficiária"
				isOpen={isOpen}
				onClose={onClose}
				width={1000}
			>
				<DestinationCreationForm
					onCreateOrUpdate={destination => {
						if (selectedPixKey === PixKey.BANK_ACCOUNT) {
							onCreateOrUpdate(destination);
							return;
						}
						setDestinationData(destination);
						setDestinationReviewModalOpen(true);
					}}
					onDelete={onDelete}
					destinations={destinations}
					isLoading={isLoading}
					selectedPixKey={selectedPixKey}
					handleSelectPixKey={selected => setSelectedPixKey(selected)}
				/>
			</Modal>

			{destinationData && destinationReviewModalOpen && (
				<DestinationReviewModal
					accountId={accountId}
					destination={destinationData}
					isOpen={destinationReviewModalOpen}
					onClose={error => {
						if (error) {
							notification.error({
								message: error?.message || '',
								description: error?.description || '',
							});
						}
						setDestinationReviewModalOpen(false);
					}}
					onConfirm={destination => {
						onCreateOrUpdate(destination);
						setDestinationReviewModalOpen(false);
					}}
					selectedPixKey={selectedPixKey}
				/>
			)}
		</>
	);
};

export default DestinationCreationModal;
