import { Col, DescriptionsProps, Row, notification } from 'antd';
import dayjs from 'dayjs';
import {
	normalizeCep,
	normalizeCnpj,
	normalizeCpfCnpj,
} from 'helpers/normalizers';
import { CompanyDocumentsList } from 'constants/company';
import { DescriptionsVertical } from 'components/DescriptionsVertical';
import { StatesListDescription } from 'constants/states';
import { formatPhoneFromObject } from 'helpers/formaters';
import { Documents } from 'components/Documents';
import { TextM } from 'components/Text';
import { PersonIcon } from 'components/Icons/Person';
import { Representative } from 'types/Representative';
import {
	RepresentativeDocumentsList,
	RepresentativeRoleDescription,
} from 'constants/representatives';
import { Heading2 } from 'components/Heading2';
import { useTheme } from 'styled-components';
import { Collapse, CollapseHeader } from 'components/Collapse';
import { Account, AccountStatus, IAccountPerson } from 'types/Account';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessType } from 'types/Access';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { CompanyDto } from 'types/Company';
import { PersonsService } from 'modules/company/services';
import { useCallback } from 'react';
import { AccountsService } from 'modules/escrow/services';
import { useParams } from 'react-router-dom';
import { queryClient } from 'network/query';

interface IEscrowDetailsInformationProps {
	account?: Account;
	hideActions?: boolean;
	viewPage?: boolean;
}
const EscrowPJDetailsInformation: React.FC<IEscrowDetailsInformationProps> = ({
	account,
	hideActions,
	viewPage,
}) => {
	const [api, contextHolder] = notification.useNotification();
	const { id } = useParams();
	const theme = useTheme();
	const { type } = useCurrentProfile();

	const { data } = useQuery({
		queryKey: ['accountDetails', id],
		queryFn: () => AccountsService.getAccount(id!),
		refetchOnWindowFocus: false,
		enabled: typeof id === 'string',
	});

	const isDocDisabled = !(
		data?.status === AccountStatus.AWAITING_DOCUMENTS_UPDATE &&
		type === AccessType.FINANCIAL_INSTITUTION
	);
	const company: IAccountPerson =
		data?.person || account?.person || ({} as IAccountPerson);
	const personId = data?.person_id || account?.person_id;

	const { mutate } = useMutation<void, ApiError, Partial<CompanyDto>>({
		mutationFn: companyDto => {
			const payload = {
				...companyDto,
				fundManagerId: companyDto.fund_manager_id || undefined,
			};

			return PersonsService.patchCompany(personId || '', payload);
		},
		onSuccess: () => {
			queryClient.refetchQueries({
				queryKey: ['accountDetails', id!],
			});
			api.success({
				message: 'Empresa atualizada com sucesso.',
			});
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema ao atualizar a empresa.',
			});
		},
	});

	const updateDocumentPerson = useCallback(
		(documentsObject: { [key: string]: string }) => {
			mutate({
				...company,
				documents: {
					...company.documents,
					...documentsObject,
				},
			});
		},
		[company],
	);

	const createRepresentativeBasicData = (
		representative: Representative,
	): DescriptionsProps['items'] => {
		return [
			{
				key: '1',
				label: 'CPF',
				children:
					normalizeCpfCnpj(representative?.taxpayer_id) || '*****',
			},
			{
				key: '2',
				label: 'Nome',
				children: representative?.full_name || '*****',
			},
			{
				key: '3',
				label: 'Nome da mãe',
				children: representative?.mother_name || '*****',
			},
			{
				key: '4',
				label: 'Data de nascimento',
				children:
					typeof representative.birth_date === 'string'
						? representative?.birth_date?.includes('*')
							? dayjs(
									representative?.birth_date,
									'YYYY-MM-DD',
								).format('DD/MM/YYYY')
							: '*****'
						: dayjs(
								representative?.birth_date,
								'YYYY-MM-DD',
							).format('DD/MM/YYYY'),
			},
			{
				key: '5',
				label: 'Função',
				children: representative.role
					? RepresentativeRoleDescription[representative?.role]
					: '*****',
			},
			{
				key: '6',
				label: 'Telefone',
				children:
					typeof representative.phone === 'string'
						? representative.phone
						: formatPhoneFromObject(representative.phone) ||
							'*****',
			},
			{
				key: '7',
				label: 'E-mail',
				children: representative.email_address || '*****',
			},
		];
	};

	const createRepresentativeAddressData = (
		representative: Representative,
	): DescriptionsProps['items'] => {
		return [
			{
				key: '1',
				label: 'CEP',
				children: representative?.address?.postal_code
					? normalizeCep(representative?.address?.postal_code)
					: '*****',
			},
			{
				key: '2',
				label: 'Estado',
				children: representative?.address?.state_code
					? StatesListDescription[representative?.address?.state_code]
					: '*****',
			},
			{
				key: '3',
				label: 'Cidade',
				children: representative?.address?.city || '*****',
			},
			{
				key: '4',
				label: 'Bairro',
				children: representative?.address?.district || '*****',
			},
			{
				key: '5',
				label: 'Rua/Avenida',
				children: representative?.address?.street_name || '*****',
			},
			{
				key: '6',
				label: 'Número',
				children: representative?.address?.street_number || '*****',
			},
			{
				key: '7',
				label: 'Complemento',
				children: representative?.address?.extra_info || '*****',
			},
		];
	};

	const colapseItems = company.representatives.map((representative, i) => {
		return {
			key: `${i + 1}`,
			label: (
				<CollapseHeader>
					<PersonIcon />
					<TextM>{representative.full_name}</TextM>
				</CollapseHeader>
			),
			children: (
				<>
					<Row gutter={[16, 16]}>
						<Col span={12}>
							<DescriptionsVertical
								hideActions={hideActions}
								borderless={true}
								title="Dados básicos"
								items={createRepresentativeBasicData(
									representative,
								)}
							/>
						</Col>
						<Col span={12}>
							<DescriptionsVertical
								hideActions={hideActions}
								borderless={true}
								title="Endereço"
								items={createRepresentativeAddressData(
									representative,
								)}
							/>
						</Col>
					</Row>
					{!hideActions && (
						<Row
							gutter={[16, 16]}
							style={{
								marginTop: '8px',
							}}
						>
							<Col span={24}>
								<Documents
									viewPage={viewPage}
									borderless
									isDisabled={isDocDisabled}
									documentsObject={representative.documents}
									documentsList={RepresentativeDocumentsList}
								/>
							</Col>
						</Row>
					)}
					{representative?.representative && (
						<Row
							gutter={[16, 16]}
							style={{
								marginTop: '1rem',
							}}
						>
							<Col span={24}>
								<Heading2
									style={{
										padding: '0 16px',
									}}
								>
									Procurador
								</Heading2>
								<Row gutter={[16, 16]}>
									<Col span={12}>
										<DescriptionsVertical
											borderless={true}
											title={null}
											items={createRepresentativeBasicData(
												representative.representative,
											)}
										/>
									</Col>
									<Col span={12}>
										<DescriptionsVertical
											borderless={true}
											title={null}
											items={createRepresentativeAddressData(
												representative.representative,
											)}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					)}
				</>
			),
		};
	});

	return (
		<>
			{contextHolder}
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<DescriptionsVertical
						title="Dados básicos"
						hideActions={hideActions}
						items={[
							{
								key: '1',
								label: 'CNPJ',
								children:
									normalizeCnpj(company?.taxpayer_id) || '-',
							},
							{
								key: '2',
								label: 'Razão Social',
								children: company?.corporate_name || '-',
							},
							{
								key: '3',
								label: 'Nome Fantasia',
								children: company?.name || '-',
							},
							{
								key: '4',
								label: 'Data de Constituição',
								children:
									typeof company?.foundation_date === 'string'
										? company?.foundation_date?.includes(
												'*',
											)
											? dayjs(
													company?.foundation_date,
													'YYYY-MM-DD',
												).format('DD/MM/YYYY')
											: '*****'
										: dayjs(
												company?.foundation_date,
												'YYYY-MM-DD',
											).format('DD/MM/YYYY'),
							},
							{
								key: '5',
								label: 'CNAE',
								children: company?.cnae || '-',
							},
							{
								key: '6',
								label: 'E-mail',
								children: company?.email_address || '-',
							},
							{
								key: '7',
								label: 'Solicitante da Conta',
								children:
									data?.requestor?.full_name ??
									'Solitante não informado',
							},
						]}
					/>
				</Col>
				<Col span={12}>
					<DescriptionsVertical
						title="Endereço"
						hideActions={hideActions}
						items={[
							{
								key: '1',
								label: 'CEP',
								children: company?.address?.postal_code
									? normalizeCep(company.address?.postal_code)
									: '-',
							},
							{
								key: '2',
								label: 'Estado',
								children:
									company.address?.state_code &&
									!company.address?.state_code.includes('*')
										? StatesListDescription[
												company.address?.state_code
											]
										: '*****',
							},
							{
								key: '3',
								label: 'Cidade',
								children: company.address?.city || '-',
							},
							{
								key: '4',
								label: 'Bairro',
								children: company.address?.district || '-',
							},
							{
								key: '5',
								label: 'Rua/Avenida',
								children: company.address?.street_name || '-',
							},
							{
								key: '6',
								label: 'Número',
								children: company.address?.street_number || '-',
							},
							{
								key: '7 ',
								label: 'Complemento',
								children: company.address?.extra_info || '-',
							},
						]}
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]} style={{ marginTop: '2rem' }}>
				<Col span={24}>
					<Documents
						isDisabled={isDocDisabled}
						documentsObject={company.documents}
						documentsList={CompanyDocumentsList}
						updateDocumentHandler={updateDocumentPerson}
					/>
				</Col>
			</Row>
			<Row
				style={{
					marginTop: '2rem',
					border: `1px solid ${theme.border}`,
					padding: '16px 20px',
					borderRadius: '6px',
				}}
			>
				<Col span={24}>
					<Heading2>Representantes</Heading2>
					<Row style={{ marginTop: '1rem' }}>
						<Col span={24}>
							<Collapse items={colapseItems} />
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};

export default EscrowPJDetailsInformation;
