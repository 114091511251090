import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { Footer } from './styles';
import { Destination, DestinationType } from 'types/Destination';
import { cpfCnpjRule, fullNameRule } from 'helpers/rules';
import { BankListOptions } from 'constants/banksList';
import { DestinationTypeDescription } from 'constants/destination';
import { normalizeBankAccount, normalizeCpfCnpj } from 'helpers/normalizers';
import { serializeOnlyNumbers } from 'helpers/serializers';
import { useState } from 'react';
import { Modal } from 'components/Modal';
import { useTheme } from 'styled-components';
import { IoCheckmark } from 'react-icons/io5';

interface IDestinationCreationNaturalPersonProps {
	isOpen: boolean;
	isLoading?: boolean;
	destination?: Destination;
	onClose: () => void;
	onCreate: (destination: Destination) => void;
}

const DestinationCreationNaturalPerson = ({
	destination: editDestination,
	isLoading,
	isOpen,
	onClose,
	onCreate,
}: IDestinationCreationNaturalPersonProps) => {
	const theme = useTheme();
	const [formValid, setFormValid] = useState(
		typeof editDestination !== 'undefined',
	);

	return (
		<Modal
			title="Adicionar Conta Beneficiária"
			isOpen={isOpen}
			onClose={onClose}
			width={600}
		>
			<Form<Destination>
				style={{ width: '100%' }}
				initialValues={{
					taxpayer_id:
						editDestination?.taxpayer_id &&
						normalizeCpfCnpj(editDestination.taxpayer_id),
					name: editDestination?.name,
					bank: editDestination?.bank,
					branch: editDestination?.branch,
					account:
						editDestination?.account &&
						normalizeBankAccount(editDestination.account),
					type: editDestination?.type,
				}}
				layout="vertical"
				onFieldsChange={(f, allFields) => {
					const errors = allFields.filter(
						field =>
							(!field.touched && !editDestination) ||
							(field.errors && field.errors.length > 0),
					);
					setFormValid(errors.length === 0);
				}}
				onFinish={values => {
					onCreate({
						...values,
						taxpayer_id: serializeOnlyNumbers(values.taxpayer_id),
						account: serializeOnlyNumbers(values.account),
						branch: values.branch.toString(),
					});
				}}
			>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Form.Item
							name="taxpayer_id"
							label="Documento do beneficiário"
							normalize={value => normalizeCpfCnpj(value)}
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								cpfCnpjRule,
							]}
						>
							<Input placeholder="CPF/CNPJ" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Form.Item
							name="name"
							label="Nome do beneficiário"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								{
									max: 50,
									message: 'Máximo de 50 caracteres',
								},
								fullNameRule('name'),
							]}
						>
							<Input placeholder="Ex.: João da Silva" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Form.Item
							name="bank"
							label="Banco"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Select
								showSearch={true}
								filterOption={(input, option) =>
									!!option?.label
										?.toLowerCase()
										?.includes(input.toLowerCase())
								}
								placeholder="Selecione"
								options={BankListOptions}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={12}>
						<Form.Item
							name="branch"
							label="Agência (sem dígito)"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<InputNumber placeholder="0000" min={0} />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="account"
							label="Conta"
							normalize={value => normalizeBankAccount(value)}
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="000000-0" min={0} />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Form.Item
							name="type"
							label="Tipo de conta"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Select
								placeholder="Selecione"
								options={Object.keys(
									DestinationTypeDescription,
								).map(key => ({
									label: DestinationTypeDescription[
										key as DestinationType
									],
									value: key,
								}))}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Footer>
					<Button
						type="default"
						loading={isLoading}
						onClick={() => {
							onClose();
						}}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							gap: '0.5rem',
							border: 'none',
							boxShadow: 'none',
							color: theme.primary,
						}}
					>
						Cancelar
					</Button>
					<Button
						type="primary"
						loading={isLoading}
						disabled={!formValid}
						htmlType="submit"
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							gap: '0.5rem',
						}}
					>
						{editDestination ? 'Salvar' : 'Adicionar'}
						<IoCheckmark />
					</Button>
				</Footer>
			</Form>
		</Modal>
	);
};

export default DestinationCreationNaturalPerson;
