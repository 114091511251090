import { Modal } from 'components/Modal';

import DestinationReview from './DestinationReview';
import { Footer } from './styles';
import { TextM, TextS } from 'components/Text';
import theme from 'theme/theme';
import { CheckIcon } from 'components/Icons/Check';
import { Destination, DestinationType } from 'types/Destination';
import { useQuery } from '@tanstack/react-query';
import { AccountsService } from 'modules/escrow/services';
import { PixKey } from 'components/PixKeySelect/PixKeySelect';
import { Button, notification, Row, Spin } from 'antd';
import { useEffect } from 'react';
import { normalizePixKeyOut } from 'modules/escrow/utils/normalizePix';
import { queryClient } from 'network/query';

interface IDestinationReviewModalProps {
	isOpen: boolean;
	destination: Destination | undefined;
	accountId?: string;
	selectedPixKey: PixKey;
	onClose: (error?: { message: string; description: string }) => void;
	onConfirm: (destination: Destination) => void;
}

const DestinationReviewModal = ({
	isOpen,
	destination,
	accountId,
	selectedPixKey,
	onClose,
	onConfirm,
}: IDestinationReviewModalProps) => {
	const [api, contextHolder] = notification.useNotification();

	const {
		data: pixDictData,
		isLoading,
		error,
		refetch,
	} = useQuery({
		queryKey: ['getPixDict', accountId],
		queryFn: () =>
			AccountsService.getDict(accountId!, {
				key: normalizePixKeyOut(
					destination?.key || '',
					destination?.key_type?.includes('CPF') ||
						destination?.key_type?.includes('CNPJ')
						? PixKey.TAXPAYER_ID
						: (destination?.key_type as PixKey),
				),
				key_type: destination?.key_type || '',
				key_owner_taxpayer_id: destination?.taxpayer_id || '',
			}),
		enabled: false,
		retry: false,
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		if (isOpen) {
			refetch();
		}
	}, [isOpen]);

	useEffect(() => {
		if (error) {
			const errorData = error as unknown as { data: { message: string } };

			queryClient.removeQueries({
				queryKey: ['getPixDict', accountId],
			});

			onClose({
				description: errorData?.data?.message,
				message: 'Ocorreu um problema ao buscar informações do Pix.',
			});
		}
	}, [error]);

	const getDestination = (value: Destination) => {
		return {
			taxpayer_id:
				selectedPixKey !== PixKey.BANK_ACCOUNT
					? pixDictData?.key_owner_taxpayer_id || ''
					: value?.taxpayer_id || '',
			name: value?.name || '',
			bank:
				selectedPixKey !== PixKey.BANK_ACCOUNT
					? pixDictData?.bank || ''
					: value?.bank || '',
			branch:
				selectedPixKey !== PixKey.BANK_ACCOUNT
					? pixDictData?.branch || ''
					: value?.branch || '',
			account:
				selectedPixKey !== PixKey.BANK_ACCOUNT
					? pixDictData?.account || ''
					: value?.account || '',
			type:
				selectedPixKey !== PixKey.BANK_ACCOUNT
					? pixDictData?.account_type || DestinationType.CACC
					: value?.type || DestinationType.CACC,
			id: value?.id || '',
			key:
				selectedPixKey !== PixKey.BANK_ACCOUNT
					? pixDictData?.key
					: value?.key || '',
			key_type:
				selectedPixKey !== PixKey.BANK_ACCOUNT
					? pixDictData?.key_type
					: value?.key_type || '',
		};
	};

	return (
		<>
			{contextHolder}
			<Modal
				title="Adicionar Conta Beneficiária"
				isOpen={isOpen}
				onClose={onClose}
				width={640}
			>
				{isLoading ? (
					<Row
						justify="center"
						style={{ minHeight: '200px', flexDirection: 'column' }}
					>
						<Spin />
						<TextM
							color={theme.primary}
							style={{ marginTop: '2rem', textAlign: 'center' }}
						>
							Carregando informações...
						</TextM>
					</Row>
				) : (
					<DestinationReview
						data={{
							bankAccountNumber: getDestination(destination!)
								?.account!,
							bankBranchNumber: getDestination(destination!)
								?.branch!,
							beneficiaryName: getDestination(destination!)
								?.name!,
							beneficiaryTaxpayerId: getDestination(destination!)
								?.taxpayer_id!,
							institutionName: getDestination(destination!)
								?.bank!,
							key: getDestination(destination!)?.key || '',
							keyType:
								getDestination(destination!)?.key_type || '',
						}}
					/>
				)}

				<Footer
					style={{
						marginTop: '2rem',
					}}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
						}}
					>
						<Button
							onClick={() => onClose()}
							type="text"
							style={{ marginRight: '1rem' }}
						>
							<TextS
								weight="bold"
								style={{
									color: theme.primary,
								}}
							>
								Cancelar
							</TextS>
						</Button>
						<Button
							type="primary"
							loading={isLoading}
							onClick={() => {
								onConfirm({
									id: '',
									account: getDestination(destination!)
										?.bank!,
									bank: getDestination(destination!)?.bank,
									branch: getDestination(destination!)
										?.branch!,
									name: getDestination(destination!)?.name!,
									taxpayer_id: getDestination(destination!)
										?.taxpayer_id!,
									key:
										getDestination(destination!)?.key || '',
									key_type:
										getDestination(destination!)
											?.key_type || '',
									type: getDestination(destination!)?.type!,
									id_end_to_end: pixDictData?.id_end_to_end,
								});
							}}
							style={{ padding: '0.5rem 1rem' }}
						>
							<TextS
								style={{
									color: theme.white,
								}}
							>
								Confirmar
							</TextS>
							<CheckIcon color={theme.white} size={18} />
						</Button>
					</div>
				</Footer>
			</Modal>
		</>
	);
};

export default DestinationReviewModal;
