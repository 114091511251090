import React from 'react';

export const CreditCards: React.FC<{
	color?: string;
	size?: string | number;
}> = ({ color, size }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={size ?? 16}
		height={size ?? 20}
		fill="none"
	>
		<path
			fill={color ?? '#7664FA'}
			fillRule="evenodd"
			d="M4.25 17.5v1.25c0 .69.56 1.25 1.25 1.25h8.75c.69 0 1.25-.56 1.25-1.25v-15c0-.69-.56-1.25-1.25-1.25h-2.5v1.563h2.188v14.375H5.812V17.5H4.25Z"
			clipRule="evenodd"
		/>
		<path
			fill={color ?? '#7664FA'}
			fillRule="evenodd"
			d="M2.063 1.563v14.374h8.124V1.563H2.063ZM1.75 0C1.06 0 .5.56.5 1.25v15c0 .69.56 1.25 1.25 1.25h8.75c.69 0 1.25-.56 1.25-1.25v-15C11.75.56 11.19 0 10.5 0H1.75Z"
			clipRule="evenodd"
		/>
		<path
			fill={color ?? '#7664FA'}
			d="M9.25 3.75a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0Z"
		/>
	</svg>
);
