import theme from 'theme/theme';
import { Address } from 'types/Address';
import { DestinationType } from 'types/Destination';
import { SelectType } from 'types/Management';

export enum PixChargeDiscountType {
	FIXED_VALUE_UNTIL_THE_DATES_INFORMED = 'FIXED_VALUE_UNTIL_THE_DATES_INFORMED',
	PERCENTAGE_DATE_REPORTED = 'PERCENTAGE_DATE_REPORTED',
}

export enum PixChargeInterestType {
	PERCENTAGE_PER_MONTH_CALENDAR_DAYS = 'PERCENTAGE_PER_MONTH_CALENDAR_DAYS',
}

export enum PixChargeDebtorType {
	PERSON = 'PERSON',
	COMPANY = 'COMPANY',
}

export enum PixChargeFineType {
	FIXED_VALUE = 'FIXED_VALUE',
	PERCENT = 'PERCENT',
}

export type DiscountPix = {
	date: string;
	amount: number;
};

export interface IPix {
	discount_type?: PixChargeDiscountType;
	discount_dates_settings?: DiscountPix[];
	amount: string;
	due_date: string;
	id: string;
	pix_key: string;
	status: EPix;
	pre_signed_url?: string;
	interest_amount?: number;
	interest_type?: PixChargeInterestType;
	expiration_after_payment: number;
	fine_amount?: number;
	fine_type?: PixChargeFineType;
	updated_at?: string;
	emv?: string;
	url?: string;
	debtor: {
		taxpayer_id: string;
		name: string;
		email_address?: string;
		address: Address;
	};
}

export type TPixFilters = {
	end_date?: string;
	start_date?: string;
	status?: EPix;
	taxpayer_id?: string;
};

export type TDictConsultPixFilters = {
	key: string;
	key_type: string;
	key_owner_taxpayer_id: string;
};

export type KeyDictAccount = {
	participant: string;
	branch: string;
	account: string;
	account_type: string;
	create_date: string;
};

export type KeyDict = {
	account: KeyDictAccount;
};

export type TDictConsultPix = {
	key: string;
	key_type: string;
	key_owner_taxpayer_id: string;
	key_owner_name: string;
	id_end_to_end: string;
	posting_id: string | null;
	key_dict: KeyDict;
	account: string;
	account_type: DestinationType;
	bank: string;
	branch: string;
};

export type TCreatePixPayload = {
	clientRequestId?: string;
	description?: string;
	amount: number;
	dueDate: string;
	expirationAfterPayment: number;
	debtor: {
		type: PixChargeDebtorType;
		name?: string;
		emailAddress?: string;
		taxpayerId: string;
		address?: Address;
	};
	fineAmount?: number;
	fineType?: PixChargeFineType;
	discountDatesSettings?: {
		settings: DiscountPix[];
		discountType: PixChargeDiscountType;
	};
	interestAmount: number;
	interestType: PixChargeInterestType;
};

export type ICreatePixForm = {
	description?: string;
	amount: number;
	dueDate: string;
	expirationAfterPayment: string; // Data a ser convertida em dias
	interestAmount: number; // juros
	interestType: PixChargeInterestType;
	fineAmount?: number; // multa
	fineType?: SelectType;

	// debtor
	type: PixChargeDebtorType;
	name: string;
	emailAddress?: string;
	taxpayerId: string;
	address?: Address;

	// discountDatesSettings
	discountAmount?: number;
	discountDate?: string;
	discountType: SelectType;
};

export enum EPix {
	EXPIRED = 'EXPIRED',
	ACTIVE = 'ACTIVE',
	PAID = 'PAID',
}

export const pixStatusDescription = {
	[EPix.EXPIRED]: 'Expirado',
	[EPix.PAID]: 'Pago',
	[EPix.ACTIVE]: 'Pendente',
};

export const pixDisplay = {
	[EPix.EXPIRED]: {
		label: pixStatusDescription[EPix.EXPIRED],
		background: theme.dangerBackground,
		textColor: theme.dangerText,
		statusColor: theme.danger,
	},
	[EPix.PAID]: {
		label: pixStatusDescription[EPix.PAID],
		background: theme.successBackground,
		textColor: theme.success,
		statusColor: theme.successLight,
	},
	[EPix.ACTIVE]: {
		label: pixStatusDescription[EPix.ACTIVE],
		statusColor: theme.orange,
		background: theme.orangeSoft,
		textColor: theme.textOrange,
	},
};
