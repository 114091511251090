import { NetworkApi } from 'network';
import { List } from 'types/List';
import {
	BarCode,
	ListPostingParams,
	Posting,
	PostingAction,
	PostingDto,
} from 'types/Posting';

export class PostingsService extends NetworkApi {
	public static createPosting(posting: PostingDto, token: string) {
		const headers = {
			'x-mfa-code': token,
		};

		return this.post<{ id: string }, PostingDto, { [key: string]: string }>(
			`/postings`,
			posting,
			headers,
		);
	}

	public static listPostings(params?: ListPostingParams) {
		return this.get<List<Posting>>('/postings', params);
	}

	public static reviewPosting(
		postingId: string,
		action: PostingAction,
		mfaToken: string,
		reason?: string,
	) {
		const headers = {
			'x-mfa-code': mfaToken,
		};
		return this.put<
			void,
			{ action: PostingAction; reason?: string },
			{
				[key: string]: string;
			}
		>(`/postings/${postingId}/review`, { action, reason }, headers);
	}

	public static getPostingDetails(postingId: string) {
		return this.get<Posting>(`/postings/${postingId}`);
	}

	public static getBarCodeDetails(barcode: string) {
		return this.get<BarCode>(`/billpayments/${barcode}`);
	}

	public static cancelPosting(postingId: string) {
		return this.put<void, {}, {}>(`/postings/${postingId}/cancel`, {});
	}
}
