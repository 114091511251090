import {
	Button,
	Card,
	Col,
	Divider,
	Form,
	Input,
	InputNumber,
	Row,
	Select,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Destination, DestinationType } from 'types/Destination';
import {
	cpfCnpjRule,
	emailRule,
	fullNameRule,
	phoneRule,
	uuidV4Rule,
} from 'helpers/rules';
import { BankListDescription, BankListOptions } from 'constants/banksList';
import { DestinationTypeDescription } from 'constants/destination';
import { normalizeBankAccount, normalizeCpfCnpj } from 'helpers/normalizers';
import { serializeOnlyNumbers } from 'helpers/serializers';
import { useTheme } from 'styled-components';
import { Heading2 } from 'components/Heading2';
import { TextM, TextS, TextSS } from 'components/Text';
import { BiPencil, BiTrash } from 'react-icons/bi';
import {
	Footer,
	StyledActions,
	StyledDestination,
	StyledEditDestination,
	StyledRemoveDestination,
} from './styles';
import { useEffect, useState } from 'react';
import { PixKeySelect } from 'components/PixKeySelect';
import { PixKey, pixKeyOptions } from 'components/PixKeySelect/PixKeySelect';
import { ConfirmationModal } from 'components/ConfirmationModal';
import {
	getPixNormalizer,
	normalizePixKeyIn,
	PixNormalizers,
} from 'modules/escrow/utils/normalizePix';

interface IDestinationCreationFormProps {
	isLoading?: boolean;
	destinations?: Destination[];
	selectedPixKey: PixKey;
	onCreateOrUpdate: (destination: Destination) => void;
	onDelete: (destinationId: string) => void;
	handleSelectPixKey: (key: PixKey) => void;
}

const DestinationCreationForm = ({
	destinations,
	isLoading,
	selectedPixKey,
	handleSelectPixKey,
	onDelete,
	onCreateOrUpdate,
}: IDestinationCreationFormProps) => {
	const theme = useTheme();
	const [form] = useForm();

	const [selectedDestination, setSelectedDestination] =
		useState<Destination>();
	const [isOpenConfirmModal, setIsOpenConfirmModal] =
		useState<boolean>(false);

	useEffect(() => {
		if (!selectedDestination) {
			form.resetFields();
		}
	}, [selectedDestination]);

	return (
		<>
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<Heading2 style={{ marginBottom: '10px' }}>
						Contas adicionadas:
					</Heading2>
					<Card
						style={{
							background: theme.background,
							maxHeight: '30rem',
							overflowY: 'auto',
						}}
					>
						{!!destinations ? (
							destinations.map((destination, index) => (
								<div key={destination.id}>
									<StyledDestination>
										<div>
											<TextSS color={theme.textSecondary}>
												<b>{destination.name}</b> -{' '}
												{normalizeCpfCnpj(
													destination.taxpayer_id,
												)}
											</TextSS>
											<TextSS color={theme.textSecondary}>
												{
													BankListDescription[
														destination.bank
													]
												}
											</TextSS>
											<TextSS color={theme.textSecondary}>
												Agência: {destination.branch} -{' '}
												{
													DestinationTypeDescription[
														destination.type
													]
												}
												:{' '}
												{normalizeBankAccount(
													destination.account,
												)}
											</TextSS>
											{!!destination.key && (
												<TextSS
													color={theme.textSecondary}
												>
													Chave Pix -{' '}
													{
														pixKeyOptions[
															destination.key_type?.includes(
																'CPF',
															) ||
															destination?.key_type?.includes(
																'CNPJ',
															)
																? PixKey.TAXPAYER_ID
																: (destination.key_type as PixKey)
														]
													}
													:{' '}
													{getPixNormalizer(
														destination?.key_type as keyof typeof PixNormalizers,
													)(
														normalizePixKeyIn(
															destination.key,
															destination.key_type as PixKey,
														),
													)}
												</TextSS>
											)}
										</div>
										<StyledActions>
											{/* Edit */}
											<StyledEditDestination
												onClick={() => {
													setSelectedDestination({
														...destination,
														key: normalizePixKeyIn(
															destination.key ||
																'',
															destination.key_type as PixKey,
														),
													});
													form.setFieldsValue({
														...destination,
														key: getPixNormalizer(
															destination.key_type as keyof typeof PixNormalizers,
														)(
															normalizePixKeyIn(
																destination.key ||
																	'',
																destination.key_type as PixKey,
															),
														),
													});
												}}
											>
												<BiPencil />
											</StyledEditDestination>
											{/* Delete */}
											<StyledRemoveDestination
												onClick={() => {
													setSelectedDestination(
														destination,
													);
													setIsOpenConfirmModal(true);
												}}
											>
												<BiTrash />
											</StyledRemoveDestination>
										</StyledActions>
									</StyledDestination>
									{index !== destinations.length - 1 && (
										<Divider />
									)}
								</div>
							))
						) : (
							<TextS color={theme.textGray}>
								Nenhuma conta beneficiária adicionada.
							</TextS>
						)}
					</Card>
				</Col>
				<Col span={12}>
					<Heading2 style={{ marginBottom: '10px' }}>
						Adicione a conta beneficiária
					</Heading2>
					<Card>
						<Form<Destination>
							style={{ width: '100%' }}
							initialValues={selectedDestination}
							layout="vertical"
							form={form}
							onFinish={values => {
								onCreateOrUpdate({
									...values,
									taxpayer_id: serializeOnlyNumbers(
										values.taxpayer_id,
									),
									account: serializeOnlyNumbers(
										values.account,
									),
									branch: values.branch?.toString(),
									id: selectedDestination?.id || '',
									key: values.key,
									key_type:
										selectedPixKey === PixKey.TAXPAYER_ID
											? values.key?.length === 11
												? 'CPF'
												: 'CNPJ'
											: selectedPixKey ===
												  PixKey.BANK_ACCOUNT
												? undefined
												: selectedPixKey,
								});
								setSelectedDestination(undefined);
								form.resetFields();
							}}
						>
							<Row gutter={[16, 16]}>
								<Col span={24}>
									<Form.Item
										name="taxpayer_id"
										label="Identificação do beneficiário"
										normalize={value =>
											normalizeCpfCnpj(value)
										}
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
											cpfCnpjRule,
										]}
									>
										<Input
											placeholder="Digite o CPF ou CNPJ"
											onChange={e => {
												form.setFieldsValue({
													[e?.target?.name || '']:
														e.target.value,
												});
												if (
													selectedPixKey ===
													PixKey.TAXPAYER_ID
												) {
													form.setFieldsValue({
														key: normalizeCpfCnpj(
															e.target.value,
														),
													});
												}
											}}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[16, 16]}>
								<Col span={24}>
									<Form.Item
										name="name"
										label="Nome do beneficiário"
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
											{
												max: 50,
												message:
													'Máximo de 50 caracteres',
											},
											fullNameRule('name'),
										]}
									>
										<Input placeholder="Ex.: João da Silva" />
									</Form.Item>
								</Col>
							</Row>

							<Row gutter={[16, 16]} style={{ margin: '1rem 0' }}>
								<PixKeySelect
									value={selectedPixKey}
									onChange={selected => {
										handleSelectPixKey(selected);
										form.setFieldsValue({
											key:
												selected === PixKey.TAXPAYER_ID
													? form.getFieldValue(
															'taxpayer_id',
														) != null
														? form.getFieldValue(
																'taxpayer_id',
															)
														: ''
													: '',
										});
									}}
								/>
							</Row>
							{selectedPixKey !== PixKey.BANK_ACCOUNT && (
								<Row gutter={[16, 16]}>
									<Col span={24}>
										<Form.Item
											name="key"
											label="Chave Pix"
											normalize={value => {
												return getPixNormalizer(
													selectedPixKey,
												)(value);
											}}
											rules={[
												{
													required: true,
													message:
														'Campo obrigatório',
												},
												...(selectedPixKey ===
												PixKey.TAXPAYER_ID
													? [cpfCnpjRule]
													: []),
												...(selectedPixKey ===
												PixKey.EMAIL
													? [emailRule('key')]
													: []),
												...(selectedPixKey ===
												PixKey.PHONE
													? [phoneRule('key')]
													: []),
												...(selectedPixKey ===
												PixKey.EVP
													? [uuidV4Rule]
													: []),
											]}
										>
											<Input
												placeholder="Digite a chave Pix"
												disabled={
													selectedPixKey ===
													PixKey.TAXPAYER_ID
												}
											/>
										</Form.Item>
									</Col>
								</Row>
							)}

							{selectedPixKey === PixKey.BANK_ACCOUNT && (
								<>
									<Row gutter={[16, 16]}>
										<Col span={24}>
											<Form.Item
												name="bank"
												label="Banco"
												rules={[
													{
														required: true,
														message:
															'Campo obrigatório',
													},
												]}
											>
												<Select
													showSearch={true}
													filterOption={(
														input,
														option,
													) =>
														!!option?.label
															?.toLowerCase()
															?.includes(
																input.toLowerCase(),
															)
													}
													placeholder="Selecione"
													options={BankListOptions}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={[16, 16]}>
										<Col span={12}>
											<Form.Item
												name="branch"
												label="Agência (sem dígito)"
												rules={[
													{
														required: true,
														message:
															'Campo obrigatório',
													},
												]}
											>
												<InputNumber
													placeholder="0000"
													min={0}
												/>
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item
												name="account"
												label="Conta"
												normalize={value =>
													normalizeBankAccount(value)
												}
												rules={[
													{
														required: true,
														message:
															'Campo obrigatório',
													},
												]}
											>
												<Input
													placeholder="000000-0"
													min={0}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={[16, 16]}>
										<Col span={24}>
											<Form.Item
												name="type"
												label="Tipo de conta"
												rules={[
													{
														required: true,
														message:
															'Campo obrigatório',
													},
												]}
											>
												<Select
													placeholder="Selecione"
													options={Object.keys(
														DestinationTypeDescription,
													).map(key => ({
														label: DestinationTypeDescription[
															key as DestinationType
														],
														value: key,
													}))}
												/>
											</Form.Item>
										</Col>
									</Row>
								</>
							)}
							<Footer>
								<Button
									type="primary"
									loading={isLoading}
									disabled={false}
									htmlType="submit"
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										gap: '0.5rem',
									}}
								>
									{!!selectedDestination?.id &&
									!isOpenConfirmModal
										? 'Editar'
										: 'Adicionar'}
								</Button>
							</Footer>
						</Form>
					</Card>
				</Col>
			</Row>
			<ConfirmationModal
				isOpen={isOpenConfirmModal}
				danger={true}
				title="Remover conta beneficiária"
				confirmText="Remover conta"
				cancelText="Cancelar"
				onConfirm={() => {
					onDelete(selectedDestination?.id || '');
					setSelectedDestination(undefined);
					setIsOpenConfirmModal(false);
				}}
				onCancel={() => setIsOpenConfirmModal(false)}
				onClose={() => setIsOpenConfirmModal(false)}
			>
				<TextM weight="normal">
					Você tem certeza que deseja remover essa conta beneficiária?
				</TextM>
			</ConfirmationModal>
		</>
	);
};

export default DestinationCreationForm;
