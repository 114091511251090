import { Modal } from 'components/Modal';
import { TextM, TextS } from 'components/Text';
import React from 'react';
import { BackgroundModalData } from './styles';
import { CompanyIcon } from 'components/Icons/Company';
import { PaymentPersonIcon } from 'components/Icons/PaymentPerson';
import theme from 'theme/theme';
import { BankListDescription } from 'constants/banksList';
import { normalizeBankAccount, normalizeCpfCnpj } from 'helpers/normalizers';

export type DestinationData = {
	institutionName: string;
	beneficiaryName: string;
	beneficiaryTaxpayerId: string;
	bankAccountNumber: string;
	bankBranchNumber: string;
	key?: string;
	keyType?: string;
};

export interface IDestinationReviewModalProps {
	data: DestinationData;
}

export interface IReviewDataItemProps {
	icon: React.ReactNode;
	label: string;
	value: React.ReactNode;
}

const ReviewDataItem = ({ icon, label, value }: IReviewDataItemProps) => {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				marginBottom: '1rem',
				gap: '1rem',
			}}
		>
			{icon}
			<div>
				<TextS
					style={{
						color: theme.textGray,
						fontSize: '12px',
					}}
				>
					{label}
				</TextS>
				{value}
			</div>
		</div>
	);
};

const DestinationReview = ({ data }: IDestinationReviewModalProps) => {
	return (
		<>
			<TextM weight="bold">Está tudo certo?</TextM>

			<BackgroundModalData
				style={{
					marginTop: '1rem',
				}}
			>
				<ReviewDataItem
					icon={<CompanyIcon color={theme.primary} />}
					label="Nome"
					value={
						<TextS weight="bold">
							{BankListDescription[data.institutionName]}
						</TextS>
					}
				/>
				<ReviewDataItem
					icon={<PaymentPersonIcon color={theme.primary} />}
					label="Nome do Beneficiário"
					value={<TextS weight="bold">{data.beneficiaryName}</TextS>}
				/>
				<ReviewDataItem
					icon={<PaymentPersonIcon color={theme.primary} />}
					label="CPF/CNPJ"
					value={
						<TextS weight="bold">
							{normalizeCpfCnpj(data.beneficiaryTaxpayerId)}
						</TextS>
					}
				/>
				<ReviewDataItem
					icon={<CompanyIcon color={theme.primary} />}
					label="Conta"
					value={
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '2rem',
							}}
						>
							<TextM weight="bold">
								C.c.:{' '}
								{normalizeBankAccount(data.bankAccountNumber)}
							</TextM>
							<TextM weight="bold">
								Ag.: {data.bankBranchNumber?.padStart(4, '0')}
							</TextM>
						</div>
					}
				/>
			</BackgroundModalData>
		</>
	);
};

export default DestinationReview;
