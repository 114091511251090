import { Button, Col, Input, Row, Select } from 'antd';
import { EscrowAccountsFiltersType } from './types';
import { useCallback, useEffect } from 'react';
import { FiltersForm, FiltersFormItem } from './styles';
import { BsPerson } from 'react-icons/bs';
import { MdOutlineAccountBalanceWallet } from 'react-icons/md';
import { normalizeCpfCnpj } from 'helpers/normalizers';
import { CustomDrawer } from 'components/CustomDrawer';
import { LuSearch } from 'react-icons/lu';
import { serializeOnlyNumbers } from 'helpers/serializers';
import { cpfCnpjRule } from 'helpers/rules';
import {
	AccountStatusDescription,
	AccountTypeDescription,
} from 'constants/account';
import { AccountStatus, AccountType } from 'types/Account';
import { CustomRangePicker } from 'components/CustomRangePicker';
import dayjs from 'dayjs';

interface IEscrowAccountsProps {
	isOpen: boolean;
	filters?: EscrowAccountsFiltersType;
	onClose: () => void;
	onApply: (filters: EscrowAccountsFiltersType) => void;
}
const EscrowAccounts = ({
	isOpen,
	filters,
	onApply,
	onClose,
}: IEscrowAccountsProps) => {
	const [form] = FiltersForm.useForm();

	const onFinish = useCallback(
		(values: EscrowAccountsFiltersType) => {
			onApply({
				...values,
				taxpayer_id:
					values.taxpayer_id && values.taxpayer_id.length > 0
						? serializeOnlyNumbers(values.taxpayer_id)
						: undefined,
				account:
					values.account && values.account.length > 0
						? serializeOnlyNumbers(values.account)
						: undefined,
				search:
					values.search && values.search.length > 0
						? values.search
						: undefined,
				creator_name:
					values.creator_name && values.creator_name.length > 0
						? values.creator_name
						: undefined,
				created_at_start_date: values.created_at_start_date
					? values.created_at_start_date
					: undefined,

				created_at_end_date: values.created_at_end_date
					? values.created_at_end_date
					: undefined,
				activated_at_start_date: values.activated_at_start_date
					? values.activated_at_start_date
					: undefined,
				activated_at_end_date: values.activated_at_end_date
					? values.activated_at_end_date
					: undefined,
				person_requestor_name:
					values.person_requestor_name &&
					values.person_requestor_name.length > 0
						? values.person_requestor_name
						: undefined,
				account_type: values.account_type,
			} as EscrowAccountsFiltersType);
		},
		[onApply],
	);

	useEffect(() => {
		if (isOpen) {
			form.setFieldsValue({
				taxpayer_id:
					filters?.taxpayer_id &&
					normalizeCpfCnpj(filters?.taxpayer_id),
				account: filters?.account,
				status: filters?.status,
				search: filters?.search,
				creator_name: filters?.creator_name,
				created_at_start_date: filters?.created_at_start_date,
				created_at_end_date: filters?.created_at_end_date,
				activated_at_start_date: filters?.activated_at_start_date,
				activated_at_end_date: filters?.activated_at_end_date,
				person_requestor_name: filters?.person_requestor_name,
				account_type: filters?.account_type,
			});
		}
	}, [isOpen, form, filters]);

	return (
		<CustomDrawer
			onClose={onClose}
			open={isOpen}
			title="Busca avançada"
			footer={
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<Button
							style={{
								display: 'flex',
								alignItems: 'center',
								width: '100%',
								justifyContent: 'center',
								position: 'relative',
							}}
							size="large"
							type="primary"
							onClick={form.submit}
						>
							Aplicar filtros
							<LuSearch
								size={21}
								style={{ position: 'absolute', right: '15px' }}
							/>
						</Button>
					</Col>
				</Row>
			}
		>
			<FiltersForm
				initialValues={{
					taxpayer_id:
						filters?.taxpayer_id &&
						normalizeCpfCnpj(filters?.taxpayer_id),
					account: filters?.account,
					status: filters?.status,
					search: filters?.search,
					creator_name: filters?.creator_name,
					created_at_start_date: filters?.created_at_start_date,
					created_at_end_date: filters?.created_at_end_date,
					activated_at_start_date: filters?.activated_at_start_date,
					activated_at_end_date: filters?.activated_at_end_date,
					person_requestor_name: filters?.person_requestor_name,
					account_type: filters?.account_type,
				}}
				layout="vertical"
				form={form}
				onFinish={onFinish}
			>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem
							name="taxpayer_id"
							label="CPF / CNPJ"
							normalize={value => normalizeCpfCnpj(value)}
							rules={[cpfCnpjRule]}
						>
							<Input
								placeholder="Digite aqui"
								prefix={<BsPerson />}
							/>
						</FiltersFormItem>
					</Col>
				</Row>

				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem name="search" label="Titular">
							<Input
								placeholder="Digite aqui"
								prefix={<BsPerson />}
							/>
						</FiltersFormItem>
					</Col>
				</Row>

				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem label="Conta" name="account">
							<Input
								placeholder="Digite aqui"
								prefix={<MdOutlineAccountBalanceWallet />}
							/>
						</FiltersFormItem>
					</Col>
				</Row>

				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem name="status" label="Status">
							<Select
								mode="multiple"
								style={{ minWidth: '10rem' }}
								placeholder="Selecionar"
								allowClear={true}
								options={Object.keys(
									AccountStatusDescription,
								).map(key => ({
									label: AccountStatusDescription[
										key as AccountStatus
									],
									value: key,
								}))}
							/>
						</FiltersFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem
							name="account_type"
							label="Tipo de Conta"
						>
							<Select
								style={{ minWidth: '10rem' }}
								placeholder="Selecionar"
								allowClear={true}
								options={Object.keys(
									AccountTypeDescription,
								).map(key => ({
									label: AccountTypeDescription[
										key as AccountType
									],
									value: key,
								}))}
							/>
						</FiltersFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem
							name="created_at_start_date"
							label="Data de Abertura"
						>
							<CustomRangePicker
								handleDateChange={dates => {
									form.setFieldsValue({
										created_at_start_date:
											dates?.[0]?.format('YYYY-MM-DD'),
										created_at_end_date:
											dates?.[1]?.format('YYYY-MM-DD'),
									});
								}}
								maxDate={dayjs()}
							/>
						</FiltersFormItem>
						<FiltersFormItem
							style={{ display: 'none' }} // hidden field but necessary for form validation set value
							name="created_at_end_date"
							label="Data de pagamento"
						/>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem
							name="activated_at_start_date"
							label="Data de Ativação"
						>
							<CustomRangePicker
								maxDate={dayjs()}
								handleDateChange={dates => {
									form.setFieldsValue({
										activated_at_start_date:
											dates?.[0]?.format('YYYY-MM-DD'),
										activated_at_end_date:
											dates?.[1]?.format('YYYY-MM-DD'),
									});
								}}
							/>
						</FiltersFormItem>
						<FiltersFormItem
							style={{ display: 'none' }} // hidden field but necessary for form validation set value
							name="activated_at_end_date"
						/>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem
							name="person_requestor_name"
							label="Soliciante"
						>
							<Input
								placeholder="Digite aqui"
								prefix={<BsPerson />}
							/>
						</FiltersFormItem>
					</Col>
				</Row>
			</FiltersForm>
		</CustomDrawer>
	);
};

export default EscrowAccounts;
