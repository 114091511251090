import { Button, Dropdown, Select, notification } from 'antd';
import { LuEye } from 'react-icons/lu';
import type { MenuProps } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import CollectionsFilters from './CollectionsFilters';
import { LuSearch } from 'react-icons/lu';
import { TextL, TextM, TextS } from 'components/Text';
import { Link, useNavigate } from 'react-router-dom';
import { Wallet, WalletContent, WalletStatus } from 'types/Wallet';
import { useCallback, useContext, useState } from 'react';
import { Table } from 'components/Table';
import { DropdownIcon, FiltersContainer } from './styles';
import { FilterButton } from 'components/FilterButton';
import { AiOutlineStop } from 'react-icons/ai';
import { useTheme } from 'styled-components';
import { CollectionsFiltersType } from './types';
import { normalizeBankAccount } from 'helpers/normalizers';
import { ESortingPage, PaginationInfo } from 'types/List';
import { EditIcon } from 'components/Icons/Edit';
import { WalletsService } from 'modules/debitCollections/services';
import { WalletCreation } from 'modules/debitCollections/components/WalletCreation';
import { AbilityContext } from 'modules/core/context/AbilityContext';
import { subject } from '@casl/ability';
import { ArchiveIcon } from 'components/Icons/Archive';
import { ApiError } from 'types/ApiError';
import { WalletStatusDescription } from 'constants/wallet';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { queryClient } from 'network/query';
import { formatAddZerosMaskInput } from 'helpers/formaters';
import { sortTableHandler } from 'helpers/tables';
import { useIsEscrowAccountRoute } from 'modules/escrow/utils/adminRoute';
import { contaLivre } from 'modules/escrow/constants/routes';

const Collections = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const ability = useContext(AbilityContext);
	const [api, contextHolder] = notification.useNotification();

	const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
		currentPage: 1,
		pageSize: 10,
	});
	const [isWalletEditOpen, setIsWalletEditOpen] = useState<
		Wallet | undefined
	>();
	const [sorting, setSorting] = useState<{
		order: ESortingPage;
		sort: string;
	}>({ order: ESortingPage.DESC, sort: 'created_at' });
	const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);
	const [isArchiveWalletModalOpen, setIsArchiveWalletModalOpen] = useState<
		string | false
	>(false);
	const [filters, setFilters] = useState<CollectionsFiltersType>(
		{} as CollectionsFiltersType,
	);

	const accountRoute = !useIsEscrowAccountRoute() ? contaLivre : 'escrow';
	const handleClearFilters = useCallback(() => {
		setFilters({} as CollectionsFiltersType);
	}, []);

	const { data, isLoading } = useQuery({
		queryKey: ['walletsList', paginationInfo, filters, sorting],
		queryFn: () =>
			WalletsService.getWallets({
				page: paginationInfo.currentPage,
				size: paginationInfo.pageSize,
				order: sorting.order,
				sort: sorting.sort,
				wallet_code: filters.wallet_code,
				name: filters.name,
				beneficiary_account: filters.beneficiary_account,
				beneficiary_document: filters.taxpayer_id,
				status: filters.status,
			}),
	});

	const { mutate: archive, isPending: isArchiving } = useMutation<
		{ id: string },
		ApiError,
		string
	>({
		mutationFn: (walletId: string) =>
			WalletsService.archiveWallet(walletId),
		onSuccess: () => {
			api.success({
				message: 'Carteira arquivada com sucesso!',
			});
			queryClient.refetchQueries({
				queryKey: ['walletsList'],
			});
		},
		onError: e => {
			console.error(e);
			api.error({
				message: 'Erro ao arquivar a carteira!',
			});
		},
	});

	const handleNavigateToDetails = (id: string) => {
		navigate(`/debit-collections/wallet/${id}/barcodes`);
	};

	const createActionItems = (item: WalletContent): MenuProps['items'] => {
		const defaultItems: MenuProps['items'] = [];

		defaultItems.push({
			label: (
				<Link to={`/debit-collections/wallet/${item.id}/barcodes`}>
					Visualizar
				</Link>
			),
			key: '0',
			icon: <LuEye size={18} />,
		});

		if (item.status !== 'ARCHIVED') {
			defaultItems.push({
				label: 'Editar',
				onClick: () =>
					setIsWalletEditOpen({
						id: item.id,
						name: item.name,
						account_id: item.account?.id ?? '',
						fine: item.fine,
						interest: item.interest,
						days_to_expire_after_payment:
							item.days_to_expire_after_payment,
						discount_modality: item.discount_modality,
						discount_value: item.discount_value,
					}),
				icon: (
					<DropdownIcon size="16px">
						<EditIcon color={theme.textSecondary} />
					</DropdownIcon>
				),
				key: '1',
			});

			if (
				ability.can(
					'ARCHIVE',
					subject('WALLET', { accountId: item.account?.id }),
				)
			) {
				defaultItems.push({
					label: 'Arquivar',
					onClick: () => setIsArchiveWalletModalOpen(item.id),
					icon: (
						<DropdownIcon size="18px">
							<ArchiveIcon color={theme.textSecondary} />
						</DropdownIcon>
					),
					key: '2',
				});
			}
		}

		return defaultItems;
	};

	const columns: ColumnsType<WalletContent> = [
		{
			title: 'Código da carteira',
			width: '15%',
			render: (wallet: WalletContent) => (
				<TextM weight="normal" style={{ textTransform: 'uppercase' }}>
					{typeof wallet.wallet_code === 'number'
						? `${formatAddZerosMaskInput(String(wallet.wallet_code))}`
						: '-'}
				</TextM>
			),
		},
		{
			title: 'Nome da carteira',
			render: (wallet: WalletContent) => (
				<TextM weight="normal">{`${wallet.name}`}</TextM>
			),
			width: '15%',
		},
		{
			title: 'Conta de destino',
			width: '13%',
			render: (wallet: WalletContent) => {
				if (wallet.account) {
					return (
						<Button
							type="link"
							style={{ padding: 0 }}
							onClick={() =>
								navigate(
									`/${accountRoute}/${wallet.account?.id}/details/info`,
								)
							}
						>
							{normalizeBankAccount(wallet.account.account)}
						</Button>
					);
				}

				return '-';
			},
		},
		{
			title: 'Status',
			width: '10%',
			render: (wallet: WalletContent) =>
				WalletStatusDescription[wallet.status],
		},
		{
			key: 'action',
			width: '10%',
			align: 'end',
			render: (account: WalletContent) => (
				<Dropdown
					menu={{ items: createActionItems(account) }}
					trigger={['click']}
				>
					<Button
						type="link"
						disabled={isArchiving}
						style={{
							color: theme.text,
							fontWeight: 'bold',
							fontSize: '1.3rem',
							lineHeight: '1rem',
							letterSpacing: '0.09rem',
						}}
					>
						...
					</Button>
				</Dropdown>
			),
		},
	];

	return (
		<>
			{contextHolder}
			<FiltersContainer>
				<div>
					<TextM style={{ marginBottom: '8px' }}>Status</TextM>
					<Select
						style={{ width: '200px', height: '32px' }}
						options={[
							{
								label: 'Todos',
								value: '',
							},
						].concat(
							Object.keys(WalletStatusDescription)
								.map(key => ({
									label: WalletStatusDescription[
										key as WalletStatus
									],
									value: key,
								}))
								.sort((a, b) => a.label.localeCompare(b.label)),
						)}
						value={filters.status ?? ''}
						onChange={value => {
							setFilters({
								...filters,
								status:
									value !== ''
										? (value as WalletStatus)
										: undefined,
							});
						}}
					/>
				</div>
				<FilterButton
					icon={<LuSearch size={18} />}
					onClick={() => setIsFiltersOpen(true)}
				>
					<TextS style={{ color: theme.white }}>Busca avançada</TextS>
				</FilterButton>
				<FilterButton
					icon={<AiOutlineStop size={18} color={theme.white} />}
					variation="secondary"
					onClick={handleClearFilters}
					disabled={Object.keys(filters).length === 0}
				>
					<TextS style={{ color: theme.white }}>Limpar filtros</TextS>
				</FilterButton>
			</FiltersContainer>
			<Table
				columns={columns}
				rowKey={record => record.id}
				dataSource={data?.content}
				loading={isLoading}
				onChange={(_, __, sorter) =>
					sortTableHandler(sorter, setSorting)
				}
				pagination={{
					total: data?.total_elements || 0,
					showTotal(total) {
						const currentSize =
							paginationInfo.currentPage *
							paginationInfo.pageSize;
						return `${currentSize > total ? total : currentSize} de ${total}`;
					},
					pageSizeOptions: ['10', '30', '60', '90'],
					showSizeChanger: true,
					pageSize: paginationInfo.pageSize,
					current: paginationInfo.currentPage,
					onChange(page, pageSize) {
						setPaginationInfo({
							currentPage: page,
							pageSize,
						});
					},
				}}
				onRow={(record: WalletContent) => ({
					onClick: event => {
						const target = event.target as HTMLElement;

						//Funciona com o componente atual
						const isDropdownTrigger = target.closest(
							'.ant-dropdown-trigger',
						);
						const isInsideDropdown =
							target.closest('.ant-dropdown');

						if (!isDropdownTrigger && !isInsideDropdown) {
							handleNavigateToDetails(record.id);
						}
					},
				})}
			/>
			<CollectionsFilters
				isOpen={isFiltersOpen}
				onApply={filters => {
					setFilters(filters);
					setIsFiltersOpen(false);
				}}
				onClose={() => setIsFiltersOpen(false)}
				filters={filters}
			/>
			<WalletCreation
				isOpen={isWalletEditOpen !== undefined}
				wallet={isWalletEditOpen}
				onClose={() => setIsWalletEditOpen(undefined)}
			/>
			<ConfirmationModal
				isOpen={isArchiveWalletModalOpen !== false}
				danger={true}
				title="Arquivar carteira"
				confirmText="Arquivar carteira"
				cancelText="Cancelar"
				isLoading={isArchiving}
				onConfirm={() => {
					if (isArchiveWalletModalOpen !== false) {
						archive(isArchiveWalletModalOpen);
					}
					setIsArchiveWalletModalOpen(false);
				}}
				onCancel={() => setIsArchiveWalletModalOpen(false)}
				onClose={() => setIsArchiveWalletModalOpen(false)}
			>
				<TextL>Você tem certeza que deseja arquivar a carteira?</TextL>
				<TextM weight="normal">
					Essa ação não poderá ser desfeita.
				</TextM>
			</ConfirmationModal>
		</>
	);
};

export default Collections;
