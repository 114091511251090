import {
	Button,
	Col,
	CollapseProps,
	Descriptions,
	DescriptionsProps,
	Flex,
	Row,
} from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { DescriptionItem } from './styles';
import { Destination } from 'types/Destination';
import { TextM, TextS } from 'components/Text';
import { useTheme } from 'styled-components';
import { normalizeBankAccount, normalizeCpfCnpj } from 'helpers/normalizers';
import { BankListDescription } from 'constants/banksList';
import { DestinationTypeDescription } from 'constants/destination';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessPermission, AccessType } from 'types/Access';
import { Account, AccountStatus } from 'types/Account';
import { Collapse, CollapseHeader } from 'components/Collapse';
import { PixKey, pixKeyOptions } from 'components/PixKeySelect/PixKeySelect';
import { FaRegTrashCan } from 'react-icons/fa6';
import { RiPencilLine } from 'react-icons/ri';
import { CreditCards } from 'components/Icons/CreditCards';
import {
	getPixNormalizer,
	normalizePixKeyIn,
	PixNormalizers,
} from 'modules/escrow/utils/normalizePix';

interface IAccountDestinationPixProps {
	account: Account;
	destinations?: Destination[];
	handleEdit: (destination: Destination) => void;
	handleDelete: (destinationId: string) => void;
}

export interface CustomDescriptionItemProps extends IChildrenProps {}

export const CustomDescriptionItem = ({
	children,
}: CustomDescriptionItemProps) => {
	return (
		<DescriptionItem style={{ margin: '0', padding: '0.5rem 0' }}>
			{children}
		</DescriptionItem>
	);
};

const createRepresentativeBasicDescriptionItems = (
	destinationData: Destination,
): DescriptionsProps['items'] => {
	const style = {
		borderBottom: '1px solid #f0f0f0',
	};

	return [
		{
			key: '1',
			label: 'CPF / CNPJ',
			style,
			children: (
				<CustomDescriptionItem>
					{normalizeCpfCnpj(destinationData.taxpayer_id)}
				</CustomDescriptionItem>
			),
		},
		{
			key: '2',
			label: 'Nome do beneficiário',
			style,
			children: (
				<CustomDescriptionItem>
					{destinationData.name}
				</CustomDescriptionItem>
			),
		},
		...(destinationData.key != null && destinationData.key !== ''
			? [
					{
						key: '0',
						label: `Chave Pix - ${
							pixKeyOptions[
								destinationData.key_type?.includes?.('CPF') ||
								destinationData?.key_type?.includes('CNPJ')
									? PixKey.TAXPAYER_ID
									: (destinationData.key_type as PixKey)
							]
						}`,
						style,
						children: (
							<CustomDescriptionItem>
								{destinationData.key_type &&
									getPixNormalizer(
										destinationData.key_type as keyof typeof PixNormalizers,
									)(
										normalizePixKeyIn(
											destinationData.key,
											destinationData.key_type as PixKey,
										),
									)}
							</CustomDescriptionItem>
						),
					},
				]
			: []),
		{
			key: '3',
			label: 'Banco',
			style,
			children: (
				<CustomDescriptionItem>
					{BankListDescription[destinationData.bank]}
				</CustomDescriptionItem>
			),
		},
		{
			key: '4',
			label: 'Agência',
			style,
			children: (
				<CustomDescriptionItem>
					{destinationData.branch?.padStart(4, '0')}
				</CustomDescriptionItem>
			),
		},
		{
			key: '5',
			label: 'Conta',
			style,
			children: (
				<CustomDescriptionItem>
					{normalizeBankAccount(destinationData.account)}
				</CustomDescriptionItem>
			),
		},
		{
			key: '6',
			label: 'Tipo de conta',
			style,
			children: (
				<CustomDescriptionItem>
					{DestinationTypeDescription[destinationData.type]}
				</CustomDescriptionItem>
			),
		},
	];
};

const AccountDestinationPix = ({
	account,
	destinations: editDestinations,
	handleEdit,
	handleDelete,
}: IAccountDestinationPixProps) => {
	const theme = useTheme();
	const { type, permissions } = useCurrentProfile();
	const isAbleToCreate =
		account.status === AccountStatus.AWAITING_BACKOFFICE_ANALYSIS;

	const [destinations, setDestinations] = useState<Destination[]>([]);

	const isAbleToCreateBeneficiary =
		type === AccessType.FINANCIAL_INSTITUTION ||
		(permissions.includes(AccessPermission.CREATE_BENEFICIARY) &&
			isAbleToCreate);

	useEffect(() => {
		if (Array.isArray(editDestinations) && editDestinations.length > 0) {
			setDestinations(editDestinations);
		}
	}, [editDestinations]);

	const destinationItems: CollapseProps['items'] = useMemo(
		() =>
			destinations?.map((destination, i) => {
				return {
					key: `${i + 1}`,
					label: (
						<CollapseHeader>
							<CreditCards />
							<TextM>{destination.name}</TextM>
						</CollapseHeader>
					),
					children: (
						<>
							<TextM weight="bold">Informações da conta</TextM>
							<Row
								gutter={[16, 16]}
								style={{ marginTop: '0.5rem' }}
							>
								<Col span={24}>
									<Descriptions
										labelStyle={{
											minWidth: '12rem',
											fontSize: '1rem',
											padding: '0.5rem 0',
										}}
										contentStyle={{
											fontSize: '1rem',
											color: theme.text,
											margin: '0',
										}}
										layout="horizontal"
										column={1}
										items={createRepresentativeBasicDescriptionItems(
											destination,
										)}
									/>
								</Col>
							</Row>
							{isAbleToCreateBeneficiary && (
								<Flex
									gap={10}
									justify="end"
									style={{ marginTop: 10 }}
								>
									<Button
										onClick={() =>
											handleDelete(destination.id)
										}
										type="primary"
										style={{
											backgroundColor: theme.danger,
											borderColor: theme.danger,
										}}
									>
										<TextS style={{ color: theme.white }}>
											Excluir
										</TextS>
										<FaRegTrashCan
											style={{ color: theme.white }}
										/>
									</Button>

									<Button
										type="primary"
										onClick={() => handleEdit(destination)}
									>
										<TextS style={{ color: theme.white }}>
											Editar
										</TextS>
										<RiPencilLine
											style={{ color: theme.white }}
										/>
									</Button>
								</Flex>
							)}
						</>
					),
				};
			}),
		[destinations],
	);

	return (
		<Row gutter={[16, 16]}>
			<Col span={24}>
				<Collapse items={destinationItems} />
			</Col>
		</Row>
	);
};

export default AccountDestinationPix;
