import { Button, Col, Empty, Flex, Row, notification } from 'antd';
import { Account, AccountStatus } from 'types/Account';
import { useCallback, useState } from 'react';
import { AccountsService } from 'modules/escrow/services';
import { TextM, TextS } from 'components/Text';
import theme from 'theme/theme';
import EmptyListImage from 'components/images/emptyListB.png';
import { IoAddCircleOutline } from 'react-icons/io5';
import { CreateAccountDestinationPixModal } from 'components/CreateAccountDestinationPixModal';
import { ConfirmationModal } from 'components/ConfirmationModal';
import SuccessModal from 'components/SuccessModal/SuccessModal';
import { useDestinations } from 'modules/escrow/hooks';
import { Destination } from 'types/Destination';
import { AccountDestinationPix } from 'components/AccountDestinationPix';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessPermission, AccessType } from 'types/Access';

interface IEscrowDetailsAccountDestinationProps {
	account: Account;
}
const EscrowDetailsAccountDestination: React.FC<
	IEscrowDetailsAccountDestinationProps
> = ({ account }) => {
	const [api, contextHolder] = notification.useNotification();
	const { type, permissions } = useCurrentProfile();

	const [isDestinationAdded, setIsDestinationAdded] =
		useState<boolean>(false);
	const [accountDestinations, setAccountDestinations] = useState<
		Destination[]
	>(account?.destinations || []);
	const [accountDestinationToEdit, setAccountDestinationToEdit] =
		useState<Destination>();
	const [deleteDestinationModalOpen, setDeleteDestinationModalOpen] =
		useState<string>();

	const isAbleToCreate =
		account.status === AccountStatus.AWAITING_BACKOFFICE_ANALYSIS;

	const isAbleToCreateBeneficiary =
		type === AccessType.FINANCIAL_INSTITUTION ||
		(permissions.includes(AccessPermission.CREATE_BENEFICIARY) &&
			isAbleToCreate);

	const [
		isNewAccountDestinationModalOpen,
		setIsNewAccountDestinationModalOpen,
	] = useState<boolean>(false);

	const getAccountsHandler = useCallback(async () => {
		if (!!account.id) {
			const response = await AccountsService.getAccount(account.id);

			if (response?.destinations) {
				setAccountDestinations(response?.destinations);
			}
			return;
		}

		api.error({
			description:
				'Adicione as contas beneficiárias em Detalhes da Conta',
			message: 'Ocorreu um problema ao buscar as contas beneficiárias.',
		});
	}, [account]);

	const handleModalSuccessClose = useCallback(() => {
		setIsDestinationAdded(true);
		setIsNewAccountDestinationModalOpen(false);
		setAccountDestinationToEdit(undefined);
	}, []);

	const handleModalDeleteClose = useCallback(() => {
		setDeleteDestinationModalOpen(undefined);
		setIsNewAccountDestinationModalOpen(false);
		setAccountDestinationToEdit(undefined);
	}, []);

	const handleModalClose = useCallback(() => {
		setIsNewAccountDestinationModalOpen(false);
		setAccountDestinationToEdit(undefined);
	}, []);

	const { createOrUpdateDestination, deleteDestination, loading } =
		useDestinations(account.id || '', {
			onCreateSuccess: () => {
				handleModalSuccessClose();
				getAccountsHandler();
			},
			onUpdateSuccess: () => {
				handleModalSuccessClose();
				getAccountsHandler();
			},
			onDeleteSuccess: () => {
				handleModalDeleteClose();
				getAccountsHandler();
			},
			onError: error => {
				handleModalClose();

				api.error({
					description: error?.data?.message || '',
					message: 'Ocorreu um problema com conta beneficiária.',
				});
			},
		});

	const handleDelete = useCallback((destinationId: string) => {
		deleteDestination(destinationId || '');
		setDeleteDestinationModalOpen(undefined);
	}, []);

	return (
		<>
			{contextHolder}
			{!account ||
			!accountDestinations ||
			accountDestinations?.length === 0 ? (
				<>
					<Flex
						style={{ marginTop: '2rem', flexDirection: 'column' }}
						justify="center"
						align="center"
					>
						<TextM weight="bold">
							Nenhuma conta beneficiárias cadastrada.
						</TextM>
						<TextM weight="bold">
							Atenção! você só pode adicionar contas beneficiárias
							antes da análise de abertura da conta.
						</TextM>
						<TextS
							style={{
								marginTop: '0.5rem',
								color: theme.textGray,
							}}
						>
							Para adicionar contas beneficiárias após a análise,
							entre em contato com a Celcoin.
						</TextS>

						<Empty
							style={{
								marginTop: '1rem',
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
							}}
							description=""
							image={EmptyListImage}
							imageStyle={{
								height: 240,
							}}
						/>

						{isAbleToCreateBeneficiary && (
							<Button
								type="primary"
								onClick={() => {
									setIsNewAccountDestinationModalOpen(true);
								}}
								style={{
									marginTop: '2rem',
									width: 'fit-content',
									padding: '0.5rem 1rem',
								}}
							>
								<Flex>
									<TextM
										style={{
											color: theme.white,
										}}
									>
										Adicionar conta beneficiária
									</TextM>
									<IoAddCircleOutline
										size={'1.4rem'}
										style={{ width: '2rem' }}
										color={theme.white}
									/>
								</Flex>
							</Button>
						)}
					</Flex>
				</>
			) : (
				<>
					<Row gutter={[16, 16]} style={{ marginTop: '2rem' }}>
						<Col span={24}>
							<AccountDestinationPix
								account={account}
								destinations={accountDestinations}
								handleDelete={destinationId => {
									setDeleteDestinationModalOpen(
										destinationId,
									);
								}}
								handleEdit={destinationToEdit => {
									setIsNewAccountDestinationModalOpen(true);
									setAccountDestinationToEdit(
										destinationToEdit,
									);
								}}
							/>
						</Col>
					</Row>
					{isAbleToCreateBeneficiary && (
						<Row justify="end" style={{ marginTop: '2rem' }}>
							<Button
								type="primary"
								onClick={() => {
									setIsNewAccountDestinationModalOpen(true);
								}}
							>
								<TextS color={theme.white}>
									Adicionar conta beneficiária
								</TextS>
								<IoAddCircleOutline
									size={'1.4rem'}
									style={{ width: '2rem' }}
									color={theme.white}
								/>
							</Button>
						</Row>
					)}
				</>
			)}
			{(isNewAccountDestinationModalOpen || accountDestinationToEdit) && (
				<CreateAccountDestinationPixModal
					isOpen={
						isNewAccountDestinationModalOpen ||
						!!accountDestinationToEdit
					}
					onClose={() => {
						setIsNewAccountDestinationModalOpen(false);
						setAccountDestinationToEdit(undefined);
					}}
					onCreateOrUpdate={destination => {
						createOrUpdateDestination({
							...destination,
							...(destination?.key &&
							destination.key.trim() !== ''
								? {
										key: destination.key,
										key_type: destination.key_type,
									}
								: {
										key: undefined,
										key_type: undefined,
										id_end_to_end: undefined,
									}),
						});
					}}
					accountId={account.id}
					isLoading={loading}
					accountDestination={accountDestinationToEdit}
				/>
			)}

			{isDestinationAdded && (
				<SuccessModal
					closeModal={() => setIsDestinationAdded(false)}
					message={'Conta Beneficiária adicionada com sucesso!'}
					title={'Conta Beneficiária adicionada'}
				/>
			)}

			<ConfirmationModal
				isOpen={!!deleteDestinationModalOpen}
				danger={true}
				title="Remover conta beneficiária"
				confirmText="Remover conta"
				cancelText="Cancelar"
				onConfirm={() => {
					handleDelete(deleteDestinationModalOpen || '');
				}}
				onCancel={() => setDeleteDestinationModalOpen(undefined)}
				onClose={() => setDeleteDestinationModalOpen(undefined)}
			>
				<TextM weight="normal">
					Você tem certeza que deseja remover essa conta beneficiária?
				</TextM>
			</ConfirmationModal>
		</>
	);
};

export default EscrowDetailsAccountDestination;
