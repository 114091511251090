import styled from 'styled-components';

export const CustomRangePickerContainer = styled.div`
	display: flex;
	border: 1px solid ${({ theme }) => theme.inputBorder};
	border-radius: 6px;
	align-items: center;
	padding: 6.4px 11px;

	.ant-picker-input {
		width: auto;
	}

	input {
		color: ${({ theme }) => theme.inputBorder};
		max-width: 80px !important;
	}

	.ant-picker {
		padding: 0;
		display: flex;
		width: 100%;

		.ant-picker-clear {
			right: 0px;
		}
	}

	.ant-picker-range-separator {
		padding: 0px 10px !important;
	}
`;

export const RangePickerFixedText = styled.p`
	margin: 0 10px 0 10px;
	color: ${({ theme }) => theme.inputBorder};
`;

export const RangePickerSeparator = styled.p`
	margin: 0;
	padding: 0;
	color: ${({ theme }) => theme.inputBorder};
`;
