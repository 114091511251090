import { TextM, TextS } from 'components/Text';
import styled from 'styled-components';

export const Footer = styled.div`
	padding-top: 0.6rem;
	justify-content: flex-end;
	display: flex;
	gap: 0.6rem;
`;

export const StyledDestination = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const StyledActions = styled.div`
	display: flex;
	align-items: center;
`;

export const StyledEditDestination = styled.div`
	cursor: pointer;
	margin-right: 1.2rem;

	svg {
		&:hover {
			color: ${({ theme }) => theme.primary};
			transition: width 0.1s;
			-webkit-transition: width 0.1s;
			size: 50px;
		}
	}
`;

export const StyledRemoveDestination = styled.div`
	cursor: pointer;

	svg {
		&:hover {
			color: ${({ theme }) => theme.danger};
			transition: width 0.1s;
			-webkit-transition: width 0.1s;
			size: 50px;
		}
	}
`;

export const BackgroundModalData = styled.div`
	width: 100%;
	background-color: #f4f4ff;
	padding: 0.2rem 1rem;
	border-radius: 1rem;

	${TextM} {
		font-family: 'Inter', sans-serif !important;
	}
	${TextS} {
		font-family: 'Inter', sans-serif !important;
		font-size: 14px;
	}
`;
