import React from 'react';

const CaseIcon: React.FC<{ color?: string }> = ({ color }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
		<path fill={color ?? '#7664FA'} d="M10.668 7.5V9h-6V7.5h6Z" />
		<path
			fill={color ?? '#7664FA'}
			fillRule="evenodd"
			d="M11.418 1.5a.75.75 0 0 0-.75-.75h-6a.75.75 0 0 0-.75.75v2.25h-3a.75.75 0 0 0-.75.75V15c0 .414.336.75.75.75h13.5a.75.75 0 0 0 .75-.75V4.5a.75.75 0 0 0-.75-.75h-3V1.5Zm-9.75 3.75h12v9h-12v-9Zm3.75-3h4.5v1.5h-4.5v-1.5Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default CaseIcon;
