export enum DestinationType {
	CACC = 'CACC',
	SVGS = 'SVGS',
	TRAN = 'TRAN',
	SLRY = 'SLRY',
}

export type DestinationDto = {
	taxpayer_id: string;
	name: string;
	bank: string;
	branch: string;
	account: string;
	type: DestinationType;
	id?: string;
	key?: string;
	key_type?: string;
	id_end_to_end?: string;
};

export type Destination = DestinationDto & {
	id: string;
};
