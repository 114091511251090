import { PixKey } from 'components/PixKeySelect/PixKeySelect';
import { normalizeCpfCnpj, normalizePhone } from 'helpers/normalizers';
import { serializeOnlyNumbers } from 'helpers/serializers';

export const normalizePixKeyIn = (pixKey: string, pixKeyType: PixKey) => {
	switch (pixKeyType) {
		case PixKey.PHONE:
			return `${pixKey}`.replace('+55', ''); //TODO: Implementar diferente quando aceitar internacional
		default:
			return pixKey;
	}
};

export const normalizePixKeyOut = (pixKey: string, pixKeyType: PixKey) => {
	switch (pixKeyType) {
		case PixKey.PHONE:
			return `+55${serializeOnlyNumbers(pixKey)}`;
		case PixKey.TAXPAYER_ID:
			return serializeOnlyNumbers(pixKey);

		default:
			return pixKey;
	}
};

export const getPixNormalizer = (key: keyof typeof PixNormalizers) => {
	return PixNormalizers[key] ?? ((value: string) => value);
};

export const PixNormalizers = {
	[PixKey.TAXPAYER_ID]: (value: string) => normalizeCpfCnpj(value),
	['CPF']: (value: string) => normalizeCpfCnpj(value),
	['CNPJ']: (value: string) => normalizeCpfCnpj(value),
	[PixKey.EMAIL]: (value: string) => value,
	[PixKey.PHONE]: (value: string) => normalizePhone(value),
	[PixKey.EVP]: (value: string) => value,
};
