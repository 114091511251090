import { ButtonPrimary } from 'components/ButtonPrimary';
import { TextS } from 'components/Text';
import React, { useCallback, useEffect, useMemo } from 'react';
import { PixKeySelectContainer } from './styles';
import theme from 'theme/theme';

export enum PixKey {
	TAXPAYER_ID = 'TAXPAYER_ID',
	PHONE = 'PHONE',
	EMAIL = 'EMAIL',
	EVP = 'EVP',
	BANK_ACCOUNT = 'BANK_ACCOUNT',
}

export const pixKeyOptions = {
	[PixKey.TAXPAYER_ID]: 'CPF/CNPJ',
	[PixKey.PHONE]: 'Telefone',
	[PixKey.EMAIL]: 'E-mail',
	[PixKey.EVP]: 'Aleatória',
	[PixKey.BANK_ACCOUNT]: 'Agência e conta',
};

export interface PixKeySelectProps {
	onChange: (value: PixKey) => void;
	value: PixKey;
}

const PixKeySelect = ({ value, onChange }: PixKeySelectProps) => {
	const getPixKeyByValue = useCallback(
		(value: string) => {
			return (
				Object.entries(pixKeyOptions).find(
					([_, val]) => val === value,
				)?.[0] || null
			);
		},
		[pixKeyOptions],
	);

	const handleClick = (selectedOption: string) => {
		return onChange(getPixKeyByValue(selectedOption) as PixKey);
	};

	return (
		<PixKeySelectContainer>
			{Object.keys(pixKeyOptions).map(option => {
				return (
					<ButtonPrimary
						key={option}
						variant={value === option ? 'primary' : 'ghost'}
						onClick={() => {
							handleClick(pixKeyOptions[option as PixKey]);
						}}
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							margin: '0 2px',
							padding: '5px',
						}}
					>
						<TextS
							weight="bold"
							style={{
								fontSize: '0.8rem',
								color:
									value === option
										? theme.white
										: theme.primary,
							}}
						>
							{pixKeyOptions[option as PixKey]}
						</TextS>
					</ButtonPrimary>
				);
			})}
		</PixKeySelectContainer>
	);
};

export default PixKeySelect;
