import React, { useEffect, useState } from 'react';
import {
	CollectionsSummaryContainer,
	CollectionSummaryCard,
	IconWrapper,
	WalletSummaryCardGrid,
} from './styles';
import { Flex, notification, Spin } from 'antd';
import Currency from 'components/Icons/CurrencyIcon';
import { TextL, TextM, TextS } from 'components/Text';
import CaseIcon from 'components/Icons/CaseIcon';
import StatusIcon from 'components/Icons/StatusIcon';
import theme from 'theme/theme';
import StatusBars from './StatusBars';
import { useQuery } from '@tanstack/react-query';
import { WalletsService } from 'modules/debitCollections/services';
import { formatCurrency } from 'helpers/normalizers';
import dayjs from 'dayjs';
import { RangePickerButton } from 'components/RangePickerButton';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessType } from 'types/Access';
import DateFilterDrawer from './DateFilterDrawer';

export interface IDebitCollectionsSummaryProps {
	walletId?: string;
}

const DebitCollectionsSummary = ({
	walletId,
}: IDebitCollectionsSummaryProps) => {
	const [api, contextHolder] = notification.useNotification();
	const [dateFrom, setDateFrom] = useState(
		dayjs().subtract(1, 'M').format('MM-YYYY'),
	);
	const [dateTo, setDateTo] = useState(dayjs().format('MM-YYYY'));
	const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
	const { type } = useCurrentProfile();

	const { data, isLoading, error } = useQuery({
		queryKey: ['debitCollectionSummary', dateFrom, dateTo],
		queryFn: () =>
			WalletsService.getWalletsSummary({
				date_from: dateFrom,
				date_to: dateTo,
				...(walletId && { wallet_id: walletId }),
			}),
		refetchOnWindowFocus: false,
		enabled: type != AccessType.FINANCIAL_INSTITUTION,
	});

	useEffect(() => {
		if (error) {
			api.error({
				description: error?.message,
				message: 'Erro ao buscar resumo de cobranças',
			});
		}
	}, [error]);

	return (
		<div style={{ marginTop: '32px' }}>
			{contextHolder}
			{isLoading ? (
				<Flex justify="center">
					<Spin />
				</Flex>
			) : error ? null : type == //todo: componente de erro para tentar retry
			  AccessType.FINANCIAL_INSTITUTION ? null : (
				<>
					<Flex justify="space-between" align="center">
						<TextL>Resumo geral</TextL>
						<RangePickerButton
							initialDate={`${dayjs(
								`${dateFrom?.split('-')[1]}-${dateFrom?.split('-')[0]}-01`,
							)
								.locale('pt-br')
								.format('MMMM YYYY')}`}
							finalDate={dayjs(
								`${dateTo?.split('-')[1]}-${dateTo?.split('-')[0]}-01`,
							)
								.locale('pt-br')
								.format('MMMM YYYY')}
							onClick={() => setIsDateFilterOpen(true)}
						/>
					</Flex>
					<CollectionsSummaryContainer>
						<CollectionSummaryCard>
							<Flex align="center" gap={16}>
								<IconWrapper>
									<Currency />
								</IconWrapper>
								<TextM
									style={{
										fontSize: '18px',
										fontFamily: 'Urbanist',
										fontWeight: 500,
									}}
								>
									Valor total a ser recebido
								</TextM>
							</Flex>
							<Flex
								style={{ marginTop: '16px' }}
								align="center"
								gap={16}
							>
								<TextL
									style={{
										fontSize: '24px',
									}}
								>
									{formatCurrency(
										data?.total_amount_to_receive ?? 0,
									)}
								</TextL>
							</Flex>
							<Flex style={{ marginTop: '2px' }} align="center">
								<TextS color={theme.textGray}>
									{data?.total_charges_to_receive ?? 0}{' '}
									cobranças
								</TextS>
							</Flex>
						</CollectionSummaryCard>
						<CollectionSummaryCard>
							<Flex align="center" gap={16}>
								<IconWrapper>
									<CaseIcon />
								</IconWrapper>
								<TextM
									style={{
										fontSize: '18px',
										fontFamily: 'Urbanist',
										fontWeight: 500,
									}}
								>
									Resumo
								</TextM>
							</Flex>

							<WalletSummaryCardGrid>
								<div>
									<TextS>Cobranças recebidas</TextS>
									<TextS color={theme.textGray}>
										{data?.received_charges ?? 0}
									</TextS>
								</div>

								<div>
									<TextS>Total recebido</TextS>
									<TextS color={theme.textGray}>
										{formatCurrency(
											data?.total_amount_received ?? 0,
										)}
									</TextS>
								</div>

								<div>
									<TextS>Cobranças em atraso</TextS>
									<TextS color={theme.textGray}>
										{data?.late_charges ?? 0}
									</TextS>
								</div>

								<div>
									<TextS>Total em atraso</TextS>
									<TextS color={theme.textGray}>
										{formatCurrency(
											data?.total_amount_late ?? 0,
										)}
									</TextS>
								</div>
							</WalletSummaryCardGrid>
						</CollectionSummaryCard>
						<CollectionSummaryCard>
							<Flex
								style={{
									flexDirection: 'column',
									height: '100%',
								}}
							>
								<Flex align="center" gap={16}>
									<IconWrapper>
										<StatusIcon />
									</IconWrapper>
									<TextM
										style={{
											fontSize: '18px',
											fontFamily: 'Urbanist',
											fontWeight: 500,
										}}
									>
										Status das cobranças
									</TextM>
								</Flex>
								<Flex
									style={{
										flex: 1,
									}}
									align="flex-end"
								>
									<StatusBars
										data={[
											{
												label: 'Recebido',
												value: data?.total_charges
													? (data?.received_charges /
															data?.total_charges) *
														100
													: 0,
												color: theme.greenLight,
											},
											{
												label: 'Em atraso',
												value: data?.total_charges
													? (data?.late_charges /
															data?.total_charges) *
														100
													: 0,
												color: theme.danger,
											},
											{
												label: 'A vencer',
												value: data?.total_charges_to_receive
													? ((data?.total_charges_to_receive -
															data?.late_charges -
															data?.received_charges) /
															data?.total_charges_to_receive) *
														100
													: 0,
												color: theme.orange,
											},
										]}
									/>
								</Flex>
							</Flex>
						</CollectionSummaryCard>
					</CollectionsSummaryContainer>
				</>
			)}
			<DateFilterDrawer
				isOpen={isDateFilterOpen}
				onClose={() => setIsDateFilterOpen(false)}
				handleFilter={dates => {
					setDateFrom(dates.dateFrom?.format('MM-YYYY'));
					setDateTo(dates.dateTo?.format('MM-YYYY'));
					setIsDateFilterOpen(false);
				}}
			/>
		</div>
	);
};

export default DebitCollectionsSummary;
