import React from 'react';

const StatusIcon: React.FC<{ color?: string }> = ({ color }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
		<path
			fill={color ?? '#7664FA'}
			d="M8.332.498a.75.75 0 0 1 .75.75v2.25a.75.75 0 0 1-1.5 0v-2.25a.75.75 0 0 1 .75-.75Zm0 11.25a.75.75 0 0 1 .75.75v2.25a.75.75 0 1 1-1.5 0v-2.25a.75.75 0 0 1 .75-.75Zm7.5-3.75a.75.75 0 0 1-.75.75h-2.25a.75.75 0 0 1 0-1.5h2.25a.75.75 0 0 1 .75.75Zm-11.25 0a.75.75 0 0 1-.75.75h-2.25a.75.75 0 0 1 0-1.5h2.25a.75.75 0 0 1 .75.75Zm9.053 5.303a.75.75 0 0 1-1.06 0l-1.591-1.59a.75.75 0 0 1 1.06-1.061l1.591 1.59a.752.752 0 0 1 0 1.061ZM5.68 5.346a.75.75 0 0 1-1.06 0l-1.59-1.59a.75.75 0 0 1 1.06-1.061l1.59 1.59a.75.75 0 0 1 0 1.061Zm-2.65 7.955a.75.75 0 0 1 0-1.06l1.59-1.591a.75.75 0 1 1 1.06 1.06l-1.59 1.591a.751.751 0 0 1-1.06 0Zm7.954-7.955a.75.75 0 0 1 0-1.06l1.59-1.591a.75.75 0 0 1 1.061 1.06l-1.59 1.591a.75.75 0 0 1-1.061 0Z"
		/>
	</svg>
);
export default StatusIcon;
