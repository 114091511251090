import { Button } from 'antd';
import styled, { css } from 'styled-components';

export interface CustomButtonProps {
	variant?: 'primary' | 'secondary' | 'ghost';
	isDisabled?: boolean;
}

export const CustomButton = styled(Button)<CustomButtonProps>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: ${({ theme, variant }) =>
		variant === 'primary' ? theme.primary : theme.white};
	width: 100%;
	padding: 0;
	opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};

	&:disabled {
		color: ${({ theme, variant }) =>
			variant === 'primary' ? theme.primary : theme.textGray};
		background-color: ${({ theme, variant }) =>
			variant === 'primary' ? theme.primaryOpacity : theme.white};
	}

	${({ variant }) =>
		variant === 'primary' &&
		css`
			&:hover {
				background-color: ${({ theme }) =>
					theme.primaryOpacity} !important;
				p {
					color: ${({ theme }) => theme.primary} !important;
				}
			}

			&:disabled {
				p {
					color: ${({ theme }) => theme.primary} !important;
				}
			}
		`}

	${({ variant }) =>
		variant === 'ghost' &&
		css`
			border: 0px;
		`}
`;
