import { Card } from 'antd';
import styled from 'styled-components';

export const CollectionsSummaryContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	gap: 32px;
	margin-top: 32px;
	margin-bottom: 32px;

	@media screen and (max-width: 924px) {
		flex-direction: column;
	}
`;

export const CollectionSummaryCard = styled(Card)`
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
	width: 100%;
	min-height: 168px;
	display: flex;
	flex-direction: column;
	flex: 1;

	.ant-card-body {
		flex: 1;
	}

	p {
		font-family: 'Inter', sans-serif;
	}
`;

export const IconWrapper = styled.div`
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	background-color: #f5f5ff;
	border: 2px solid #7c5df9;
	box-shadow: 0px 5px 10px rgba(124, 93, 249, 0.2);
`;

export const WalletSummaryCardGrid = styled.div`
	margin-top: 16px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 16px;

	@media (max-width: 768px) {
		grid-template-columns: 1fr;
	}
`;

// STATUS BAR
export const StatusBarWrapper = styled.div`
	margin-top: 16px;
`;

export const ProgressBarContainer = styled.div`
	width: 100%;
	height: 12px;
	background-color: #e0e0e0;
	border-radius: 6px;
	display: flex;
	overflow: hidden;
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
`;

export const ProgressSegment = styled.div<{ width: number; color: string }>`
	height: 100%;
	width: ${props => props.width}%;
	background-color: ${props => props.color};
	transition: width 0.5s ease-in-out;
`;

export const LegendContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
`;

export const LegendItem = styled.div`
	text-align: center;
	font-size: 14px;
	font-weight: bold;
	color: ${props => props.color};
`;
