import { ButtonProps } from 'antd';
import { CustomButton } from './styles';

export interface ButtonPrimaryProps extends ButtonProps {
	onClick: () => void;
	children: React.ReactNode;
	icon?: React.ReactNode;
	position?: 'left' | 'right';
	variant?: 'primary' | 'secondary' | 'ghost';
	disabled?: boolean;
}

const ButtonPrimary = ({
	children,
	onClick,
	variant = 'primary',
	disabled,
	...props
}: ButtonPrimaryProps) => {
	return (
		<CustomButton
			onClick={onClick}
			variant={variant}
			isDisabled={disabled}
			disabled={disabled}
			{...props}
		>
			{children}
		</CustomButton>
	);
};

export default ButtonPrimary;
