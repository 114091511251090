import { useQuery } from '@tanstack/react-query';
import { Col, Descriptions, Divider, Flex, Row, Spin } from 'antd';
import { CustomDrawer } from 'components/CustomDrawer';
import { TextM, TextS } from 'components/Text';
import { BankListOptions } from 'constants/banksList';
import dayjs from 'dayjs';
import {
	formatCurrency,
	normalizeBankAccount,
	normalizeBoleto,
	normalizeCpfCnpj,
} from 'helpers/normalizers';
import { AccountsService, PostingsService } from 'modules/escrow/services';
import { getStatementMovementType } from 'modules/escrow/utils/statementDescription';
import theme from 'theme/theme';
import {
	AccountStatementMovement,
	AccountStatementMovementType,
	IStatementChargeStatusResponse,
	StatementStatusResponse,
} from 'types/Account';
import { PostingStatus } from 'types/Posting';
import BillPaymentDrawerContent from './BillPaymentDrawerContent';

export interface StatementDetailDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	statement: AccountStatementMovement | undefined;
	accountId?: string;
}

const StatementDetailDrawer = ({
	isOpen,
	statement,
	accountId,
	onClose,
}: StatementDetailDrawerProps) => {
	const { data, isLoading, error } = useQuery({
		queryKey: ['accountStatement', statement],
		queryFn: () =>
			AccountsService.getStatementStatus(
				accountId || '',
				statement?.client_code || '',
			),
		staleTime: 5000,
		enabled:
			statement !== undefined &&
			statement?.movement_type !== AccountStatementMovementType.CHARGEIN,
	});

	const {
		data: chargeStatus,
		isLoading: chargeStatusLoading,
		error: chargeStatusError,
	} = useQuery({
		queryKey: ['chargeStatus', statement],
		queryFn: () =>
			AccountsService.getStatementChargeStatus(
				accountId || '',
				statement?.client_code || '',
			),
		staleTime: 5000,
		enabled:
			statement !== undefined &&
			statement?.movement_type === AccountStatementMovementType.CHARGEIN,
	});

	const {
		data: postingDetailList,
		isLoading: postingDetailListLoading,
		error: postingDetailListError,
	} = useQuery({
		queryKey: ['postingDetails', statement],
		queryFn: () =>
			PostingsService.listPostings({
				billpayment_txn_id_auth: data!.transaction_id_authorize!,
				limit: 1,
				page: 1,
				status: PostingStatus.SUCCESS,
			}),
		enabled: data?.transaction_id_authorize !== undefined,
	});

	const createDebitInfo = (data: StatementStatusResponse | undefined) => {
		if (!data) return [];
		return [
			{
				key: '1',
				label: 'Nome',
				children: data.debit_party.name,
			},
			{
				key: '2',
				label: 'CPF/CNPJ',
				children: normalizeCpfCnpj(data.debit_party.tax_id),
			},
			{
				key: '4',
				label: 'Dados da conta',
				children: `${data.debit_party.branch} / ${normalizeBankAccount(
					data.debit_party.account,
				)}`,
			},
		];
	};

	const createChargeDebitInfo = (
		data: IStatementChargeStatusResponse | undefined,
	) => {
		if (!data) return [];
		return [
			{
				key: '1',
				label: 'Nome',
				children: data.debit_party.name,
			},
			{
				key: '2',
				label: 'CPF/CNPJ',
				children: normalizeCpfCnpj(data.debit_party.tax_id),
			},
		];
	};

	const createCreditInfo = (
		data:
			| StatementStatusResponse
			| IStatementChargeStatusResponse
			| undefined,
	) => {
		if (!data) return [];
		return [
			{
				key: '1',
				label: 'Nome',
				children: data.credit_party.name,
			},
			{
				key: '2',
				label: 'CPF/CNPJ',
				children: normalizeCpfCnpj(data.credit_party.tax_id),
			},
			{
				key: '3',
				label: 'Banco',
				children:
					BankListOptions.find(
						bank => bank.value === data.credit_party.bank,
					)?.label || '-',
			},
			{
				key: '4',
				label: 'Dados da conta',
				children: `${data.credit_party.branch} / ${normalizeBankAccount(
					data.credit_party.account,
				)}`,
			},
		];
	};

	return (
		<CustomDrawer onClose={onClose} open={isOpen} title="Detalhes">
			{isLoading || postingDetailListLoading || chargeStatusLoading ? (
				<Row justify="center">
					<Spin />
				</Row>
			) : error || postingDetailListError || chargeStatusError ? (
				<TextM>
					Ocorreu um erro ao buscar os detalhes da transação
				</TextM>
			) : (
				<>
					<Row
						style={{
							padding: '0 0.5rem',
							flexDirection: 'column',
							marginBottom: '1rem',
						}}
					>
						<TextM
							style={{
								color: theme.primary,
								fontWeight: 500,
							}}
						>
							{getStatementMovementType(statement!)}
						</TextM>
						{chargeStatus != null ? (
							<Row>
								<Flex
									justify="space-between"
									style={{
										width: '100%',
									}}
								>
									<TextS
										style={{
											fontSize: '0.8rem',
											color: theme.textGray,
										}}
									>
										Data do pagamento
									</TextS>
									<TextS
										style={{
											fontSize: '0.8rem',
											color: theme.textGray,
										}}
									>
										{dayjs(
											chargeStatus?.payment_date,
										).format('DD/MM/YYYY')}{' '}
										às{' '}
										{dayjs(
											chargeStatus?.payment_date,
										).format('HH:mm:ss')}
									</TextS>
								</Flex>
							</Row>
						) : (
							<TextS
								style={{
									fontSize: '0.8rem',
									color: theme.textGray,
								}}
							>
								{dayjs(statement?.create_date).format(
									'DD/MM/YYYY',
								)}{' '}
								às{' '}
								{dayjs(statement?.create_date).format(
									'HH:mm:ss',
								)}
							</TextS>
						)}

						<Row>
							<Flex
								justify="space-between"
								style={{
									width: '100%',
								}}
							>
								<TextS weight="bold">Valor</TextS>
								<TextS weight="bold">
									{statement?.amount
										? formatCurrency(statement?.amount)
										: '-'}
								</TextS>
							</Flex>
						</Row>
					</Row>
					<Col
						style={{
							padding: '0 0.5rem',
							display: 'flex',
							flexDirection: 'column',
							gap: '1rem',
						}}
					>
						{data ? (
							statement?.movement_type ===
								AccountStatementMovementType.BILLPAYMENT &&
							postingDetailList ? (
								<BillPaymentDrawerContent
									postingDetail={
										postingDetailList?.content?.[0]
									}
								/>
							) : (
								<>
									<Divider
										style={{
											margin: 0,
										}}
									/>
									<TextS>Recebido por:</TextS>
									<Descriptions
										column={1}
										size="small"
										items={createCreditInfo(data)}
									/>
									<TextS>Pago por:</TextS>
									<Descriptions
										column={1}
										size="small"
										items={createDebitInfo(data)}
									/>
								</>
							)
						) : chargeStatus ? (
							<>
								<Divider
									style={{
										margin: 0,
									}}
								/>
								<TextS>Recebido por:</TextS>
								<Descriptions
									column={1}
									size="small"
									items={createCreditInfo(chargeStatus)}
								/>
								<TextS>Dados do sacado:</TextS>
								<Descriptions
									column={1}
									size="small"
									items={createChargeDebitInfo(chargeStatus)}
								/>
							</>
						) : (
							<></>
						)}
					</Col>
				</>
			)}
		</CustomDrawer>
	);
};

export default StatementDetailDrawer;
