import { NetworkApi } from 'network';
import { Ability, AccessContent } from 'types/Access';
import { List } from 'types/List';
import { UserContent } from 'types/User';

export class AuthService extends NetworkApi {
	public static requestResetPassword(
		username: string,
		financialInstitutionId: string,
	) {
		return this.post<
			void,
			{ email_address: string; financial_institution_id: string },
			void
		>('/users/reset-password/request', {
			email_address: username,
			financial_institution_id: financialInstitutionId,
		});
	}

	public static confirmResetPassword(
		username: string,
		resetPasswordToken: string,
	) {
		return this.post<
			{ temporary_password: string },
			{ email_address: string; reset_password_token: string },
			void
		>('/users/reset-password/confirm', {
			email_address: username,
			reset_password_token: resetPasswordToken,
		});
	}

	public static getAccess() {
		return this.getWithoutContext<List<AccessContent>>(`/users/me/access`);
	}

	public static getAbility() {
		return this.get<Ability>(`/users/me/ability`);
	}

	public static getProfile() {
		return this.get<UserContent>(`/users/me`);
	}
}
