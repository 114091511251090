import {
	WalletChargeStatus,
	WalletDiscountModality,
	WalletStatus,
} from 'types/Wallet';

export const WalletDiscountModalityDescription = {
	[WalletDiscountModality.FIXED]: 'Monetário',
	[WalletDiscountModality.PERCENT]: 'Percentual',
	[WalletDiscountModality.NONE]: 'Sem desconto',
};

export const WalletStatusDescription = {
	[WalletStatus.ACTIVE]: 'Ativa',
	[WalletStatus.ARCHIVED]: 'Arquivada',
};

export const WalletChargeStatusDescription = {
	[WalletChargeStatus.ERROR]: 'Erro',
	[WalletChargeStatus.PENDING]: 'Pendente',
	[WalletChargeStatus.PROCESSING]: 'Processando',
	[WalletChargeStatus.CONFIRMED]: 'Confirmado',
	[WalletChargeStatus.PENDING_CREATION]: 'Pendente de criação',
	[WalletChargeStatus.PENDING_CANCELLATION]: 'Pendente de cancelamento',
	[WalletChargeStatus.EXPIRED]: 'Expirado',
	[WalletChargeStatus.CANCELED]: 'Cancelado',
};
