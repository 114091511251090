import { Modal } from 'components/Modal';
import { Posting, PostingAction } from 'types/Posting';
import { useCallback, useEffect, useMemo, useState } from 'react';
import PostingRejectReview from './PostingRejectReview';
import { usePostings } from 'modules/escrow/hooks/usePostings';
import { ApiError } from 'types/ApiError';
import PostingRejectConfirmation from './PostingRejectConfirmation';
import PostingRejectSuccess from './PostingRejectSuccess';
import { queryClient } from 'network/query';

enum PostingRejectSteps {
	REVIEW = 'REVIEW',
	CONFIRMATION = 'CONFIRMATION',
	SUCCESS = 'SUCCESS',
}

export interface PostingRejectProps {
	isOpen: boolean;
	postingData?: Posting[];
	onClose: () => void;
	onConfirm: () => void;
	onError: (e: ApiError) => void;
}

const PostingReject = ({
	isOpen,
	postingData,
	onClose,
	onConfirm,
	onError,
}: PostingRejectProps) => {
	const [comment, setComment] = useState('');
	const [step, setStep] = useState<PostingRejectSteps>(
		PostingRejectSteps.REVIEW,
	);

	useEffect(() => {
		if (isOpen) {
			setStep(PostingRejectSteps.REVIEW);
		}
	}, [isOpen]);

	const { reviewPosting, isUpdating } = usePostings({
		onReviewSuccess: () => {
			setStep(PostingRejectSteps.SUCCESS);
			queryClient.refetchQueries({
				queryKey: ['listPostings'],
			});
		},
		onError: e => onError(e),
	});

	const handleConfirm = useCallback(
		async (mfaToken: string) => {
			if (!postingData) {
				onClose();
				return;
			}
			if (postingData.length > 1) {
				for (const posting of postingData) {
					reviewPosting({
						action: PostingAction.REJECT,
						postingId: posting.id,
						reason: comment,
						mfaToken,
					});
				}
				return;
			}
			reviewPosting({
				action: PostingAction.REJECT,
				postingId: postingData[0].id,
				reason: comment,
				mfaToken,
			});
		},
		[postingData, comment, reviewPosting, onClose],
	);

	const title = useMemo(() => {
		if (step === PostingRejectSteps.SUCCESS)
			return postingData && postingData?.length > 1
				? 'Solicitações rejeitadas!'
				: 'Solicitação rejeitada!';
		return postingData && postingData?.length > 1
			? 'Rejeitar solicitações'
			: 'Rejeitar solicitação';
	}, [step, postingData]);

	const size = useMemo(() => {
		if (step === PostingRejectSteps.REVIEW) return 600;
		return 480;
	}, [step]);

	return (
		<Modal isOpen={isOpen} width={size} title={title} onClose={onClose}>
			{step === PostingRejectSteps.REVIEW && postingData && (
				<PostingRejectReview
					onClose={onClose}
					postingData={postingData}
					onConfirm={comment => {
						setComment(comment);
						setStep(PostingRejectSteps.CONFIRMATION);
					}}
				/>
			)}
			{step === PostingRejectSteps.CONFIRMATION && (
				<PostingRejectConfirmation
					isLoading={isUpdating}
					onClose={onClose}
					onConfirm={(mfaToken: string) => handleConfirm(mfaToken)}
				/>
			)}
			{step === PostingRejectSteps.SUCCESS && postingData && (
				<PostingRejectSuccess
					onConfirm={onConfirm}
					postingData={postingData}
				/>
			)}
		</Modal>
	);
};

export default PostingReject;
