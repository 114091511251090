import { Button, Row } from 'antd';
import { ConfirmationToken } from 'components/ConfirmationToken';
import { Modal } from 'components/Modal';
import { TextL, TextM, TextS } from 'components/Text';
import React, { useEffect } from 'react';
import theme from 'theme/theme';
import { User } from 'types/User';

export interface IConfirmationResetUserAccessModalProps {
	isOpen: boolean;
	title: string;
	isLoading: boolean;
	userData?: User;
	onClose: () => void;
	onConfirm: (user: User, mfaToken: string) => void;
}

const ConfirmationResetUserAccessModal = ({
	isOpen,
	title,
	isLoading,
	userData,
	onClose,
	onConfirm,
}: IConfirmationResetUserAccessModalProps) => {
	const [currentStep, setCurrentStep] = React.useState(0);

	const handleConfirm = (mfaToken: string) => {
		if (currentStep === 1) {
			if (!userData) return;
			onConfirm(userData, mfaToken);
		}
	};

	const handleClose = () => {
		onClose();
	};

	useEffect(() => {
		if (isOpen) {
			setCurrentStep(0);
		}
	}, [isOpen]);

	return (
		<Modal title={title} isOpen={isOpen} onClose={handleClose}>
			<main
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					minHeight: '100px',
				}}
			>
				{currentStep === 0 && (
					<div>
						<TextL>
							Você tem certeza que deseja resetar o acesso do
							usuário?
						</TextL>
					</div>
				)}
				{currentStep === 1 && (
					<ConfirmationToken
						isLoading={isLoading}
						onClose={onClose}
						onConfirm={token => handleConfirm(token)}
					>
						<TextM>
							Para sua segurança, realize a autenticação do seu
							dispositivo para aprovar o reset de acesso de
							usuário.
						</TextM>
					</ConfirmationToken>
				)}
			</main>

			{
				// Buttons
				currentStep === 0 && (
					<Row
						style={{
							flexDirection: 'column',
							marginTop: '2rem',
							gap: '1rem',
						}}
					>
						<Button
							type="primary"
							onClick={() => setCurrentStep(1)}
							style={{
								height: '45px',
							}}
						>
							<TextM
								style={{
									color: theme.white,
								}}
							>
								{currentStep === 0 ? 'Confirmar' : 'Resetar'}
							</TextM>
						</Button>
						<Button
							type="text"
							onClick={handleClose}
							style={{
								height: '45px',
							}}
						>
							<TextM
								style={{
									color: theme.primary,
								}}
							>
								Cancelar
							</TextM>
						</Button>
					</Row>
				)
			}
		</Modal>
	);
};

export default ConfirmationResetUserAccessModal;
