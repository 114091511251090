import { useContext, useState } from 'react';
import { Button, notification } from 'antd';
import { UserCreation } from 'components/UserCreation';
import { Company } from 'types/Company';
import { AccessListContent } from 'types/Access';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { TextL, TextM } from 'components/Text';
import { useAccess } from 'modules/users/hooks/useAccess';
import cognito from 'modules/core/services/CognitoService';
import { ProfileContext } from 'modules/core/context/ProfileContext';
import { queryClient } from 'network/query';
import { AccessListTable } from 'components/AccessListTable';
import { LuPlusCircle } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import { InformationModal } from 'components/InformationModal';
import { CheckmarkIcon } from 'components/Icons/Checkmark';
import { ApiError } from 'types/ApiError';
import { useMutation } from '@tanstack/react-query';
import { UsersService } from 'modules/users/services';
import { ConfirmationResetUserAccessModal } from 'components/ConfirmationResetUserAccessModal';

interface IPersonDetailsUsersProps {
	company: Company;
}
const NaturalPersonDetailsUsers: React.FC<IPersonDetailsUsersProps> = ({
	company,
}) => {
	const navigate = useNavigate();
	const { userProfile, userAccess } = useContext(ProfileContext);
	const [api, contextHolder] = notification.useNotification();
	const [isUserCreationSuccessOpen, setIsUserCreationSuccessOpen] =
		useState(false);
	const [isUserCreationOpen, setIsUserCreationOpen] = useState(false);
	const [userAccessToDelete, setUserAccessToDelete] = useState<
		AccessListContent | undefined
	>();
	const [userAccessToReset, setUserAccessToReset] =
		useState<AccessListContent | null>(null);
	const [isUserAccessResetSuccessOpen, setIsUserAccessResetSuccessOpen] =
		useState(false);

	const { deleteAccess, isLoading } = useAccess({
		onError: error => {
			api.error({
				description: error.data.message,
				message: 'Erro ao excluir acesso.',
			});
		},
		onSuccess: () => {
			api.success({
				message: 'Acesso excluído com sucesso.',
			});

			if (
				userAccessToDelete?.user.email_address ===
				userProfile?.email_address
			) {
				setUserAccessToDelete(undefined);
				if (userAccess && userAccess.length === 1) {
					cognito.signOut();
				}

				window.location.reload();
			}

			queryClient.refetchQueries({
				queryKey: ['accessList', company.id],
			});
		},
	});

	const { mutate, isPending, error } = useMutation<
		void,
		ApiError,
		{ id: string; emailAddress: string; mfaToken: string }
	>({
		mutationFn: ({ id, emailAddress, mfaToken }) =>
			UsersService.udpateUser(id, emailAddress, mfaToken),
		onSuccess: () => {
			setUserAccessToReset(null);
			setIsUserAccessResetSuccessOpen(true);
		},
		onError: error => {
			setUserAccessToReset(null);

			api.error({
				message: 'Erro ao resetar acesso de usuário.',
				description: error?.data?.message || '',
			});
		},
	});

	return (
		<>
			{contextHolder}
			<AccessListTable
				companyId={company.id}
				actions={
					<Button
						type="primary"
						onClick={() => setIsUserCreationOpen(true)}
					>
						Criar Usuário
						<LuPlusCircle
							style={{
								marginLeft: '0.4rem',
								marginBottom: '0.1rem',
							}}
							size={14}
						/>
					</Button>
				}
				onView={access => navigate(`/users/${access.user.id}`)}
				onDelete={access => setUserAccessToDelete(access)}
				onResetAccess={access => {
					setUserAccessToReset(access);
				}}
			/>
			<UserCreation
				isOpen={isUserCreationOpen}
				companyId={company.id}
				onClose={() => {
					setIsUserCreationOpen(false);
				}}
				onSuccess={() => {
					setIsUserCreationOpen(false);
					setIsUserCreationSuccessOpen(true);
				}}
			/>
			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={isUserCreationSuccessOpen}
				title="Acesso criado com sucesso!"
				icon={<CheckmarkIcon />}
				message="Acesso criado com sucesso!"
				onClose={() => setIsUserCreationSuccessOpen(false)}
			/>
			<ConfirmationModal
				isOpen={!!userAccessToDelete}
				danger={true}
				title="Excluir Acesso"
				confirmText="Excluir Acesso"
				cancelText="Cancelar"
				isLoading={isLoading}
				onConfirm={() => {
					deleteAccess(
						userAccessToDelete!.user.id,
						userAccessToDelete!.id,
					);
					setUserAccessToDelete(undefined);
				}}
				onCancel={() => setUserAccessToDelete(undefined)}
				onClose={() => setUserAccessToDelete(undefined)}
			>
				<TextL>Você tem certeza que quer excluir esse acesso?</TextL>
				<TextM weight="normal">
					Essa ação não poderá ser desfeita.
				</TextM>
			</ConfirmationModal>

			<ConfirmationResetUserAccessModal
				title="Resetar Acesso"
				userData={userAccessToReset?.user}
				isOpen={!!userAccessToReset}
				onClose={() => setUserAccessToReset(null)}
				onConfirm={(user, mfaToken) =>
					mutate({
						id: user!.id,
						emailAddress: user!.email_address,
						mfaToken,
					})
				}
				isLoading={isPending}
			/>

			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={isUserAccessResetSuccessOpen}
				title="Acesso resetado com sucesso!"
				icon={<CheckmarkIcon />}
				message="Acesso resetado com sucesso!"
				onClose={() => setIsUserAccessResetSuccessOpen(false)}
			/>
		</>
	);
};

export default NaturalPersonDetailsUsers;
