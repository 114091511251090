import { CustomDrawer } from 'components/CustomDrawer';
import { Col, DatePicker, Form, Row } from 'antd';
import { LuSearch } from 'react-icons/lu';

import dayjs from 'dayjs';
import { ButtonPrimary } from 'components/ButtonPrimary';
import { TextM } from 'components/Text';
import { useTheme } from 'styled-components';
import {
	walletSummaryDateFromRule,
	walletSummaryDateToRule,
} from 'helpers/rules';
import { localeDatePicker } from 'helpers/dates';

export interface DateFilterDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	handleFilter: (filter: any) => void;
}

const INITIAL_VALUES = {
	dateFrom: dayjs().subtract(1, 'M'),
	dateTo: dayjs(),
};

const DateFilterValidation = {
	dateFrom: walletSummaryDateFromRule,
	dateTo: walletSummaryDateToRule,
};

const DateFilterDrawer = ({
	isOpen,
	onClose,
	handleFilter,
}: DateFilterDrawerProps) => {
	const theme = useTheme();
	const [form] = Form.useForm();

	return (
		<CustomDrawer onClose={onClose} open={isOpen} title="Filtrar data">
			<Col
				style={{
					height: '100%',
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
				}}
			>
				<Form
					form={form}
					layout="vertical"
					initialValues={{
						dateFrom: INITIAL_VALUES.dateFrom,
						dateTo: INITIAL_VALUES.dateTo,
					}}
					onFinish={values => {
						handleFilter({
							dateFrom: values.dateFrom,
							dateTo: values.dateTo,
						});

						onClose();
					}}
				>
					<Form.Item
						name="dateFrom"
						label="Data inicial"
						rules={[
							{
								required: true,
								message: 'Campo obrigatório',
							},
							DateFilterValidation.dateFrom,
						]}
					>
						<DatePicker
							style={{
								width: '100%',
							}}
							picker="month"
							format={'MM/YYYY'}
							locale={localeDatePicker}
						/>
					</Form.Item>
					<Form.Item
						name="dateTo"
						label="Data final"
						rules={[
							{
								required: true,
								message: 'Campo obrigatório',
							},
							DateFilterValidation.dateTo,
						]}
					>
						<DatePicker
							style={{
								width: '100%',
							}}
							picker="month"
							format={'MM/YYYY'}
							locale={localeDatePicker}
						/>
					</Form.Item>
				</Form>
				<Row style={{ width: '100%' }}>
					<ButtonPrimary
						onClick={form.submit}
						style={{ padding: '1.4rem' }}
					>
						<TextM style={{ color: theme.white, margin: '0 auto' }}>
							Buscar
						</TextM>
						<LuSearch size="1.3rem" color={theme.white} />
					</ButtonPrimary>
				</Row>
			</Col>
		</CustomDrawer>
	);
};

export default DateFilterDrawer;
