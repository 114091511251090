import { AxiosError } from 'axios';

export const errorMap = {
	['Invalid context.']:
		'Você não possui permissão para executar esta ação. O contexto da requisição é inválido.',
};

export const getErrorMessage = (error: AxiosError<Record<string, unknown>>) => {
	const message = error.response?.data?.message;
	return message
		? errorMap[message as keyof typeof errorMap] || message
		: 'Erro interno no servidor';
};
