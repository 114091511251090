import { formatToFixed } from 'helpers/normalizers';
import {
	LegendContainer,
	LegendItem,
	ProgressBarContainer,
	ProgressSegment,
} from './styles';

export interface StatusBarItem {
	label: string;
	value: number;
	color: string;
}

export interface StatusBarsProps {
	data: StatusBarItem[];
}

const StatusBars = ({ data }: StatusBarsProps) => {
	const allZero = data.every(d => d.value === 0);

	const filteredData = [...data]
		.sort((a, b) => b.value - a.value)
		.filter(item => allZero || item.value > 0);

	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<LegendContainer>
				{filteredData.map((item, index) => (
					<LegendItem key={index} color={item.color}>
						{item.label} <br /> {formatToFixed(item.value, 2)}%
					</LegendItem>
				))}
			</LegendContainer>

			<ProgressBarContainer>
				{filteredData.map((item, index) => (
					<ProgressSegment
						key={index}
						width={item.value}
						color={item.color}
					/>
				))}
			</ProgressBarContainer>
		</div>
	);
};

export default StatusBars;
