import {
	Button,
	Col,
	Form,
	Input,
	InputNumber,
	notification,
	Row,
	Select,
	Spin,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Modal } from 'components/Modal';
import { PixKeySelect } from 'components/PixKeySelect';
import { PixKey } from 'components/PixKeySelect/PixKeySelect';
import { BankListOptions } from 'constants/banksList';
import { DestinationTypeDescription } from 'constants/destination';
import { normalizeBankAccount, normalizeCpfCnpj } from 'helpers/normalizers';
import {
	cpfCnpjRule,
	emailRule,
	fullNameRule,
	phoneRule,
	uuidV4Rule,
} from 'helpers/rules';
import { serializeOnlyNumbers } from 'helpers/serializers';

import { useEffect, useState } from 'react';
import { Destination, DestinationType } from 'types/Destination';
import { Footer } from './styles';
import DestinationReview from 'modules/escrow/components/DestinationCreationModal/DestinationReview';
import { TextM, TextS } from 'components/Text';
import theme from 'theme/theme';
import { useQuery } from '@tanstack/react-query';
import { AccountsService } from 'modules/escrow/services';
import {
	getPixNormalizer,
	normalizePixKeyIn,
	normalizePixKeyOut,
	PixNormalizers,
} from 'modules/escrow/utils/normalizePix';
import { queryClient } from 'network/query';

interface ICreateAccountDestinationPixModalProps {
	isOpen: boolean;
	accountId?: string;
	isLoading?: boolean;
	accountDestination?: Destination;
	onClose: () => void;
	onCreateOrUpdate: (destination: Destination, selectedKey: string) => void;
}

enum Steps {
	FORM = 0,
	CONFIRMATION = 1,
}

const CreateAccountDestinationPixModal = ({
	isOpen,
	accountId,
	isLoading,
	accountDestination,
	onClose,
	onCreateOrUpdate,
}: ICreateAccountDestinationPixModalProps) => {
	const [form] = useForm();
	const [api, contextHolder] = notification.useNotification();

	const [currentStep, setCurrentStep] = useState<Steps>(Steps.FORM);
	const [selectedDestination, setSelectedDestination] = useState<
		Destination | undefined
	>(accountDestination);
	const [selectedPixKey, setSelectedPixKey] = useState<PixKey>(
		PixKey.BANK_ACCOUNT,
	);

	const handleClose = () => {
		form.resetFields();
		setSelectedDestination(undefined);
		setSelectedPixKey(PixKey.TAXPAYER_ID);
		setCurrentStep(Steps.FORM);
		onClose();
	};

	const {
		data: pixDictData,
		isLoading: pixDictIsLoading,
		isFetching,
		error,
		refetch,
	} = useQuery({
		queryKey: [
			'getPixDict',
			accountId,
			selectedDestination,
			selectedPixKey,
		],
		queryFn: () =>
			AccountsService.getDict(accountId!, {
				key: normalizePixKeyOut(
					selectedDestination?.key || '',
					selectedDestination?.key_type?.includes('CPF') ||
						selectedDestination?.key_type?.includes('CNPJ')
						? PixKey.TAXPAYER_ID
						: (selectedDestination?.key_type as PixKey),
				),
				key_type: selectedDestination?.key_type || '',
				key_owner_taxpayer_id: selectedDestination?.taxpayer_id || '',
			}),
		enabled: false,
		refetchOnWindowFocus: false,
		retry: false,
	});

	const getDestination = (value: Destination) => {
		return {
			taxpayer_id:
				selectedPixKey !== PixKey.BANK_ACCOUNT
					? pixDictData?.key_owner_taxpayer_id || ''
					: value?.taxpayer_id || '',
			name: value?.name || '',
			bank:
				selectedPixKey !== PixKey.BANK_ACCOUNT
					? pixDictData?.bank || ''
					: value?.bank || '',
			branch:
				selectedPixKey !== PixKey.BANK_ACCOUNT
					? pixDictData?.branch || ''
					: value?.branch || '',
			account:
				selectedPixKey !== PixKey.BANK_ACCOUNT
					? pixDictData?.account || ''
					: value?.account || '',
			type:
				selectedPixKey !== PixKey.BANK_ACCOUNT
					? pixDictData?.account_type || DestinationType.CACC
					: value?.type || DestinationType.CACC,
			id: value?.id || '',
			key:
				selectedPixKey !== PixKey.BANK_ACCOUNT
					? pixDictData?.key
					: value?.key || '',
			key_type:
				selectedPixKey !== PixKey.BANK_ACCOUNT
					? pixDictData?.key_type
					: value?.key_type || '',
		};
	};

	const handleSubmit = async (values: Destination) => {
		if (currentStep === Steps.FORM) {
			const destination = {
				...values,
				id: selectedDestination?.id || '',
				taxpayer_id: serializeOnlyNumbers(values.taxpayer_id),
				account: serializeOnlyNumbers(values.account),
				bank: values.bank || '',
				branch: values.branch?.toString(),
				key: values.key,
				key_type:
					selectedPixKey === PixKey.TAXPAYER_ID
						? values.key?.length === 11
							? 'CPF'
							: 'CNPJ'
						: selectedPixKey === PixKey.BANK_ACCOUNT
							? undefined
							: selectedPixKey,
			};
			setSelectedDestination(destination);
			setCurrentStep(Steps.CONFIRMATION);
		}

		if (currentStep === Steps.CONFIRMATION) {
			onCreateOrUpdate(
				{
					...getDestination(selectedDestination!),
					id_end_to_end: pixDictData?.id_end_to_end,
				},
				selectedPixKey,
			);
			setSelectedDestination(undefined);
			form.resetFields();
		}
	};

	useEffect(() => {
		if (error) {
			const errorData = error as unknown as { data: { message: string } };
			api.error({
				description: errorData?.data?.message,
				message: 'Ocorreu um problema ao buscar informações do Pix.',
			});
			queryClient.resetQueries({
				queryKey: ['getPixDict'],
			});
			setCurrentStep(Steps.FORM);
		}
	}, [error]);

	useEffect(() => {
		if (isOpen) {
			setSelectedDestination(undefined);
			form.resetFields();
			setCurrentStep(Steps.FORM);
		}
	}, [isOpen]);

	useEffect(() => {
		if (accountDestination) {
			const destination = {
				...accountDestination,
				taxpayer_id: `${normalizeCpfCnpj(accountDestination.taxpayer_id)}`,
				key: getPixNormalizer(
					accountDestination.key_type as keyof typeof PixNormalizers,
				)(
					normalizePixKeyIn(
						accountDestination?.key || '',
						accountDestination.key_type as PixKey,
					),
				),
			};
			setSelectedDestination(destination);
			form.setFieldsValue(destination);
			setSelectedPixKey(
				(accountDestination?.key_type?.includes('CPF') ||
				accountDestination?.key_type?.includes('CNPJ')
					? PixKey.TAXPAYER_ID
					: (accountDestination.key_type as PixKey)) ||
					PixKey.BANK_ACCOUNT,
			);
		}
	}, [accountDestination]);

	useEffect(() => {
		if (
			currentStep === Steps.CONFIRMATION &&
			selectedDestination?.key != null
		) {
			refetch();
		}
	}, [currentStep, selectedDestination]);

	return (
		<Modal
			isOpen={isOpen}
			title="Adicionar Conta Beneficiária"
			onClose={handleClose}
			width={640}
		>
			{contextHolder}
			{isLoading || isFetching ? (
				<Row
					justify="center"
					style={{ minHeight: '200px', flexDirection: 'column' }}
				>
					<Spin />
					<TextM
						color={theme.primary}
						style={{ marginTop: '2rem', textAlign: 'center' }}
					>
						Carregando informações...
					</TextM>
				</Row>
			) : (
				<>
					<Form<Destination>
						style={{ width: '100%' }}
						initialValues={selectedDestination}
						layout="vertical"
						form={form}
						onFinish={handleSubmit}
					>
						<>
							{currentStep === Steps.CONFIRMATION && (
								<DestinationReview
									data={{
										institutionName:
											getDestination(selectedDestination!)
												.bank || '-',
										beneficiaryName:
											getDestination(selectedDestination!)
												.name || '-',
										beneficiaryTaxpayerId:
											getDestination(selectedDestination!)
												.taxpayer_id || '-',
										key:
											getDestination(selectedDestination!)
												.key || '-',
										keyType:
											getDestination(selectedDestination!)
												.key_type || '-',
										bankBranchNumber:
											getDestination(selectedDestination!)
												.branch || '-',
										bankAccountNumber:
											getDestination(selectedDestination!)
												.account || '-',
									}}
								/>
							)}

							{currentStep === Steps.FORM && (
								<>
									<Row gutter={[16, 16]}>
										<Col span={24}>
											<Form.Item
												name="taxpayer_id"
												label="Identificação do beneficiário"
												normalize={value =>
													normalizeCpfCnpj(value)
												}
												rules={[
													{
														required: true,
														message:
															'Campo obrigatório',
													},
													cpfCnpjRule,
												]}
											>
												<Input
													placeholder="Digite o CPF ou CNPJ"
													onChange={e => {
														form.setFieldsValue({
															[e?.target?.name ||
															'']: e.target.value,
														});
														if (
															selectedPixKey ===
															PixKey.TAXPAYER_ID
														) {
															form.setFieldsValue(
																{
																	key: normalizeCpfCnpj(
																		e.target
																			.value,
																	),
																},
															);
														}
													}}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={[16, 16]}>
										<Col span={24}>
											<Form.Item
												name="name"
												label="Nome do beneficiário"
												rules={[
													{
														required: true,
														message:
															'Campo obrigatório',
													},
													{
														max: 50,
														message:
															'Máximo de 50 caracteres',
													},
													fullNameRule('name'),
												]}
											>
												<Input placeholder="Ex.: João da Silva" />
											</Form.Item>
										</Col>
									</Row>

									<Row
										gutter={[16, 16]}
										style={{ margin: '1rem 0' }}
									>
										<PixKeySelect
											value={selectedPixKey}
											onChange={selected => {
												setSelectedPixKey(selected);
												form.setFieldsValue({
													key:
														selected ===
														PixKey.TAXPAYER_ID
															? form.getFieldValue(
																	'taxpayer_id',
																) != null
																? form.getFieldValue(
																		'taxpayer_id',
																	)
																: ''
															: '',
												});
											}}
										/>
									</Row>
									{selectedPixKey !== PixKey.BANK_ACCOUNT && (
										<Row gutter={[16, 16]}>
											<Col span={24}>
												<Form.Item
													name="key"
													label="Chave Pix"
													normalize={value => {
														return getPixNormalizer(
															selectedPixKey,
														)(value);
													}}
													rules={[
														{
															required: true,
															message:
																'Campo obrigatório',
														},
														...(selectedPixKey ===
														PixKey.TAXPAYER_ID
															? [cpfCnpjRule]
															: []),
														...(selectedPixKey ===
														PixKey.EMAIL
															? [emailRule('key')]
															: []),
														...(selectedPixKey ===
														PixKey.PHONE
															? [phoneRule('key')]
															: []),
														...(selectedPixKey ===
														PixKey.EVP
															? [uuidV4Rule]
															: []),
													]}
												>
													<Input
														placeholder="Digite a chave Pix"
														disabled={
															selectedPixKey ===
															PixKey.TAXPAYER_ID
														}
													/>
												</Form.Item>
											</Col>
										</Row>
									)}

									{selectedPixKey === PixKey.BANK_ACCOUNT && (
										<>
											<Row gutter={[16, 16]}>
												<Col span={24}>
													<Form.Item
														name="bank"
														label="Banco"
														rules={[
															{
																required: true,
																message:
																	'Campo obrigatório',
															},
														]}
													>
														<Select
															showSearch={true}
															filterOption={(
																input,
																option,
															) =>
																!!option?.label
																	?.toLowerCase()
																	?.includes(
																		input.toLowerCase(),
																	)
															}
															placeholder="Selecione"
															options={
																BankListOptions
															}
														/>
													</Form.Item>
												</Col>
											</Row>
											<Row gutter={[16, 16]}>
												<Col span={12}>
													<Form.Item
														name="branch"
														label="Agência (sem dígito)"
														rules={[
															{
																required: true,
																message:
																	'Campo obrigatório',
															},
														]}
													>
														<InputNumber
															placeholder="0000"
															min={0}
														/>
													</Form.Item>
												</Col>
												<Col span={12}>
													<Form.Item
														name="account"
														label="Conta"
														normalize={value =>
															normalizeBankAccount(
																value,
															)
														}
														rules={[
															{
																required: true,
																message:
																	'Campo obrigatório',
															},
														]}
													>
														<Input
															placeholder="000000-0"
															min={0}
														/>
													</Form.Item>
												</Col>
											</Row>
											<Row gutter={[16, 16]}>
												<Col span={24}>
													<Form.Item
														name="type"
														label="Tipo de conta"
														rules={[
															{
																required: true,
																message:
																	'Campo obrigatório',
															},
														]}
													>
														<Select
															placeholder="Selecione"
															options={Object.keys(
																DestinationTypeDescription,
															).map(key => ({
																label: DestinationTypeDescription[
																	key as DestinationType
																],
																value: key,
															}))}
														/>
													</Form.Item>
												</Col>
											</Row>
										</>
									)}
								</>
							)}
						</>
					</Form>
				</>
			)}

			<Footer>
				<>
					<Button
						type="text"
						loading={false}
						disabled={false}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							gap: '0.5rem',
						}}
						onClick={
							currentStep === Steps.CONFIRMATION
								? () => setCurrentStep(Steps.FORM)
								: handleClose
						}
					>
						<TextS color={theme.primary}>
							{currentStep === Steps.CONFIRMATION
								? 'Voltar'
								: 'Cancelar'}
						</TextS>
					</Button>

					<Button
						type="primary"
						loading={pixDictIsLoading || isFetching || isLoading}
						disabled={pixDictIsLoading || isFetching || isLoading}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							gap: '0.5rem',
						}}
						onClick={form.submit}
					>
						{!!selectedDestination?.id ? 'Editar' : 'Adicionar'}
					</Button>
				</>
			</Footer>
		</Modal>
	);
};

export default CreateAccountDestinationPixModal;
