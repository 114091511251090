import { useEffect, useMemo, useRef, useState } from 'react';
import {
	Button,
	Col,
	Flex,
	Form,
	Input,
	InputRef,
	Row,
	Select,
	notification,
} from 'antd';
import { normalizeCnpj, normalizePhone } from 'helpers/normalizers';
import { DatePicker } from 'components/DatePicker';
import { StyledSelect, Footer, Spinner } from './styles';
import {
	CompanyDocuments,
	CompanyDto,
	BusinessType,
	Company,
} from 'types/Company';
import {
	CompanyDocumentsList,
	CompanyTypeDescription,
} from 'constants/company';
import { CompanyForm } from './types';
import {
	serializeOnlyNumbers,
	serializePhoneObject,
} from 'helpers/serializers';
import FormItem from 'antd/es/form/FormItem';
import dayjs from 'dayjs';
import { formatPhoneFromObject } from 'helpers/formaters';
import { PiSpinnerBold } from 'react-icons/pi';
import {
	cnpjRule,
	emailRule,
	maxTodayDateRule,
	phoneRule,
} from 'helpers/rules';
import { useCompaniesList, useCompany } from 'modules/company/hooks';
import { validateCnpj } from 'helpers/validators';
import { Heading2 } from 'components/Heading2';
import { Documents } from 'components/Documents';
import { TextS } from 'components/Text';
import {
	companyRevenueOptions,
	getCompanyRevenueOption,
} from 'modules/company/utils/options';
import { parsedCnaeData } from 'modules/company/utils/cnae';
import { SaveIcon } from 'components/Icons/Save';
import AddressContainer from 'components/Address/Address';
import { Address } from 'types/Address';
import theme from 'theme/theme';

interface ICompanyInformationProps {
	company?: Company | CompanyDto;
	onNext: (company: Company | CompanyDto) => void;
	cnpj?: string;
}
const CompanyInformation = ({
	company,
	onNext,
	cnpj,
}: ICompanyInformationProps) => {
	const [form] = Form.useForm();
	const legalNameRef = useRef<InputRef>(null);
	const taxpayerIdRef = useRef<InputRef>(null);
	const [api, contextHolder] = notification.useNotification();

	const [address, setAddress] = useState<Address>(
		company?.address || ({} as Address),
	);
	const [isDocumentsInvalid, setIsDocumentsInvalid] =
		useState<boolean>(false);
	const [uploadDocuments, setUploadDocuments] = useState<CompanyDocuments>(
		{} as CompanyDocuments,
	);

	const size = 1000;

	const { companies: managerCompanies } = useCompaniesList({
		initialFilters: {
			business_type: BusinessType.MANAGER,
			size: size,
		},
	});
	const { companies: custodianCompanies } = useCompaniesList({
		initialFilters: {
			business_type: BusinessType.CUSTODIAN,
			size: size,
		},
	});
	const { companies: adminCompanies } = useCompaniesList({
		initialFilters: {
			business_type: BusinessType.ADMINISTRATOR,
			size: size,
		},
	});

	const {
		getCompanyByTaxpayerId,
		company: loadedCompany,
		loading,
	} = useCompany({
		onError: e => {
			api.error({
				description: e.data?.message,
				message:
					'Ocorreu um problema ao buscar informações da empresa.',
			});
		},
		onSuccess: newCompany => {
			if (!cnpj) {
				api.error({
					message: 'Empresa já cadastrada',
					description: `${newCompany?.name || 'Empresa'} já está cadastrada no sistema.`,
				});
				form.setFieldValue('taxpayer_id', '');
				setTimeout(() => taxpayerIdRef.current?.focus(), 100);
				return;
			}

			if (cnpj) {
				form.setFieldValue('taxpayer_id', cnpj);
			}

			form.setFieldValue('corporate_name', newCompany?.corporate_name);
			form.setFieldValue('name', newCompany?.name);

			form.setFieldValue('business_type', newCompany?.business_type);
			form.setFieldValue(
				'foundation_date',
				dayjs(newCompany?.foundation_date, 'YYYY-MM-DD'),
			);
			form.setFieldValue('cnae', newCompany?.cnae);
			form.setFieldValue('email_address', newCompany?.email_address);
			form.setFieldValue(
				'phone',
				formatPhoneFromObject(newCompany?.phone),
			);
			form.setFieldValue(
				'average_monthly_revenue',
				newCompany?.average_monthly_revenue,
			);
			form.setFieldValue('fund_manager_id', newCompany?.fund_manager_id);
			form.setFieldValue(
				'fund_securitizer_id',
				newCompany?.fund_securitizer_id,
			);
			form.setFieldValue(
				'fund_administrator_id',
				newCompany?.fund_administrator_id,
			);

			setAddress(newCompany?.address || ({} as Address));
			setUploadDocuments(
				newCompany?.documents || ({} as CompanyDocuments),
			);
		},
	});

	useEffect(() => {
		if (company && company.documents) {
			setUploadDocuments(company.documents);
		}
	}, [company]);

	const isDocumentsValid = useMemo(() => {
		return (
			(typeof uploadDocuments['income_statement'] === 'string' &&
				typeof uploadDocuments['incorporation_certificate'] ===
					'string' &&
				typeof uploadDocuments['last_contract_amendment'] ===
					'string' &&
				typeof uploadDocuments['proof_of_address'] === 'string') ||
			(typeof uploadDocuments['income_statement_url'] === 'string' &&
				typeof uploadDocuments['incorporation_certificate_url'] ===
					'string' &&
				typeof uploadDocuments['last_contract_amendment_url'] ===
					'string' &&
				typeof uploadDocuments['proof_of_address_url'] === 'string')
		);
	}, [uploadDocuments]);

	return (
		<>
			{contextHolder}
			<Form<CompanyForm>
				form={form}
				layout="vertical"
				initialValues={{
					business_type: company?.business_type,
					taxpayer_id: normalizeCnpj(
						cnpj ? cnpj : company?.taxpayer_id || '',
					),
					corporate_name: company?.corporate_name,
					name: company?.name,
					foundation_date:
						company?.foundation_date &&
						dayjs(company.foundation_date, 'YYYY-MM-DD'),
					cnae: company?.cnae,
					email_address: company?.email_address,
					phone:
						company?.phone && formatPhoneFromObject(company.phone),
					average_monthly_revenue: getCompanyRevenueOption(
						parseInt(
							company?.average_monthly_revenue?.toString() || '',
						),
					),
					fund_manager_id: company?.fund_manager_id,
					fund_securitizer_id: company?.fund_securitizer_id,
					fund_administrator_id: company?.fund_administrator_id,
				}}
				onFinish={values => {
					const isFund = values.business_type === BusinessType.FUND;

					if (!isDocumentsValid) {
						setIsDocumentsInvalid(true);

						return api.error({
							description:
								'Anexe os documentos que estão pendentes antes de salvar.',
							message: 'Documentos não encontrados',
						});
					} else {
						onNext({
							business_type: values.business_type,
							taxpayer_id: serializeOnlyNumbers(
								values.taxpayer_id,
							),
							corporate_name: values.corporate_name,
							name: values.name,
							foundation_date:
								values.foundation_date.format('YYYY-MM-DD'),
							cnae: values.cnae,
							email_address: values.email_address,
							phone: serializePhoneObject(values.phone),
							average_monthly_revenue: parseInt(
								company?.average_monthly_revenue?.toString() ||
									'',
							),
							address: address,
							documents: uploadDocuments,
							fund_manager_id: isFund
								? values.fund_manager_id
								: undefined,
							fund_administrator_id: isFund
								? values.fund_administrator_id
								: undefined,
							fund_securitizer_id: isFund
								? values.fund_securitizer_id
								: undefined,
						} as Company);
					}
				}}
			>
				<Heading2
					style={{
						margin: '1rem 0',
						color: theme.primary,
					}}
				>
					Dados básicos
				</Heading2>

				<Row gutter={[16, 16]}>
					<Col span={5}>
						<Form.Item
							name="business_type"
							label="Tipo"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Select
								placeholder="Selecione"
								options={Object.keys(BusinessType).map(key => ({
									label: CompanyTypeDescription[
										key as BusinessType
									],
									value: key,
								}))}
							/>
						</Form.Item>
					</Col>
					<Col span={19}>
						<FormItem
							shouldUpdate={(prevValues, currentValues) =>
								prevValues.business_type !==
								currentValues.business_type
							}
						>
							{({ getFieldValue }) => (
								<Row gutter={[16, 16]}>
									{getFieldValue('business_type') ===
										BusinessType.FUND && (
										<>
											<Col span={8}>
												<Form.Item
													name="fund_manager_id"
													label="Empresa Gestora"
													rules={[
														{
															required: true,
															message:
																'Campo obrigatório',
														},
													]}
												>
													<Select
														placeholder="Selecione"
														filterOption={(
															input,
															option,
														) =>
															option?.label
																?.toLowerCase()
																?.startsWith(
																	input.toLowerCase(),
																) ||
															String(
																option?.value,
															)
																?.toLowerCase()
																?.startsWith(
																	input.toLowerCase(),
																)
														}
														options={managerCompanies.map(
															company => ({
																label: company.name,
																value: company.id,
															}),
														)}
														showSearch
													/>
												</Form.Item>
											</Col>
											<Col span={8}>
												<Form.Item
													name="fund_securitizer_id"
													label="Empresa Custodiante"
													rules={[
														{
															required: true,
															message:
																'Campo obrigatório',
														},
													]}
												>
													<Select
														placeholder="Selecione"
														filterOption={(
															input,
															option,
														) =>
															option?.label
																?.toLowerCase()
																?.startsWith(
																	input.toLowerCase(),
																) ||
															String(
																option?.value,
															)
																?.toLowerCase()
																?.startsWith(
																	input.toLowerCase(),
																)
														}
														options={custodianCompanies.map(
															company => ({
																label: company.name,
																value: company.id,
															}),
														)}
														showSearch
													/>
												</Form.Item>
											</Col>
											<Col span={8}>
												<Form.Item
													name="fund_administrator_id"
													label="Empresa Administradora"
													rules={[
														{
															required: true,
															message:
																'Campo obrigatório',
														},
													]}
												>
													<Select
														placeholder="Selecione"
														filterOption={(
															input,
															option,
														) =>
															option?.label
																?.toLowerCase()
																?.startsWith(
																	input.toLowerCase(),
																) ||
															String(
																option?.value,
															)
																?.toLowerCase()
																?.startsWith(
																	input.toLowerCase(),
																)
														}
														options={adminCompanies.map(
															company => ({
																label: company.name,
																value: company.id,
															}),
														)}
														showSearch
													/>
												</Form.Item>
											</Col>
										</>
									)}
								</Row>
							)}
						</FormItem>
					</Col>
				</Row>

				<Row gutter={[16, 16]}>
					<Col span={5}>
						<FormItem
							shouldUpdate={(prevValues, currentValues) =>
								prevValues.taxpayer_id !==
								currentValues.taxpayer_id
							}
						>
							{({ getFieldValue }) => {
								return (
									<Form.Item
										name="taxpayer_id"
										label="CNPJ"
										normalize={e => normalizeCnpj(e)}
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
											cnpjRule,
										]}
									>
										<Input
											disabled={loading}
											placeholder="00.000.000/0000-00"
											ref={taxpayerIdRef}
											onBlur={() => {
												const taxpayerId =
													getFieldValue(
														'taxpayer_id',
													);
												if (
													validateCnpj(taxpayerId) &&
													!loading
												) {
													getCompanyByTaxpayerId(
														serializeOnlyNumbers(
															taxpayerId,
														),
													);
												}
											}}
											suffix={
												loading ? (
													<Spinner>
														<PiSpinnerBold
															style={{
																fontSize: 24,
															}}
														/>
													</Spinner>
												) : null
											}
										/>
									</Form.Item>
								);
							}}
						</FormItem>
					</Col>
					<Col span={9}>
						<Form.Item
							name="corporate_name"
							label="Razão Social"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								{
									max: 50,
									message: 'Máximo de 50 caracteres',
								},
							]}
						>
							<Input
								ref={legalNameRef}
								placeholder="Ex.: Empresa S.A."
							/>
						</Form.Item>
					</Col>
					<Col span={10}>
						<Form.Item
							name="name"
							label="Nome Fantasia"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								{
									max: 50,
									message: 'Máximo de 50 caracteres',
								},
							]}
						>
							<Input placeholder="Ex.: Empresa XPTO" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={5}>
						<Form.Item
							name="foundation_date"
							label="Data de Constituição"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								maxTodayDateRule('foundation_date'),
							]}
						>
							<DatePicker
								placeholder="DD/MM/AAAA"
								format="DD/MM/YYYY"
							/>
						</Form.Item>
					</Col>
					<Col span={19}>
						<StyledSelect>
							<Form.Item
								name="cnae"
								label="CNAE"
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
								]}
							>
								<Select
									style={{ width: '100%' }}
									showSearch
									filterOption={(input, option) =>
										option?.label
											?.toLowerCase()
											?.includes(input.toLowerCase()) ||
										String(option?.value)
											?.toLowerCase()
											?.includes(input.toLowerCase())
									}
									placeholder="Selecione o CNAE"
									options={parsedCnaeData}
								/>
							</Form.Item>
						</StyledSelect>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={10}>
						<Form.Item
							name="email_address"
							label="E-mail"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								emailRule('email_address'),
							]}
						>
							<Input placeholder="Ex.: exemplo@exemplo.com" />
						</Form.Item>
					</Col>
					<Col span={7}>
						<Form.Item
							name="phone"
							label="Telefone"
							normalize={e => normalizePhone(e)}
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								phoneRule('phone'),
							]}
						>
							<Input placeholder="(00) 00000-0000" />
						</Form.Item>
					</Col>
					<Col span={7}>
						<Form.Item
							name="average_monthly_revenue"
							label="Faixa de Faturamento"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Select
								placeholder="Selecione a faixa de faturamento"
								options={companyRevenueOptions.map(option => ({
									label: option.label,
									value: option.value,
								}))}
							/>
						</Form.Item>
					</Col>
				</Row>
				<br />

				<AddressContainer setAddress={setAddress} address={address} />

				<Row
					gutter={[16, 16]}
					justify="space-between"
					style={{ alignItems: 'center', padding: '0 0.7rem' }}
				>
					<Heading2
						style={{ marginBottom: '1rem', color: theme.primary }}
					>
						Documentos
					</Heading2>
					<TextS>* Obrigatórios</TextS>
				</Row>
				<Documents
					hideTitle={true}
					onChangeDocumentsObject={documents => {
						setUploadDocuments({
							...uploadDocuments,
							...documents,
						});
					}}
					documentsObject={uploadDocuments}
					documentsList={CompanyDocumentsList}
				/>
				{isDocumentsInvalid && (
					<TextS color={theme.error} style={{ paddingTop: '1rem' }}>
						* Adicione todos os documentos obrigatórios
					</TextS>
				)}
				<Footer>
					<Button type="primary" htmlType="submit">
						<Flex>
							<TextS
								color={theme.white}
								style={{
									marginRight: '0.7rem',
								}}
							>
								Salvar alterações
							</TextS>
							<SaveIcon color={theme.white} size={21} />
						</Flex>
					</Button>
				</Footer>
			</Form>
		</>
	);
};

export default CompanyInformation;
