import { TextM } from 'components/Text';
import { ConfirmationToken } from 'components/ConfirmationToken';

interface IPostingApprovalConfirmationProps {
	isLoading: boolean;
	onClose: () => void;
	onConfirm: (mfaToken: string) => void;
}
const PostingApprovalConfirmation = ({
	isLoading,
	onClose,
	onConfirm,
}: IPostingApprovalConfirmationProps) => {
	return (
		<ConfirmationToken
			isLoading={isLoading}
			onClose={onClose}
			onConfirm={onConfirm}
		>
			<TextM>
				Para sua segurança, realize a autenticação do seu dispositivo
				para aprovar essa transferência.
			</TextM>
		</ConfirmationToken>
	);
};

export default PostingApprovalConfirmation;
