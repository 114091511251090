import React, { useCallback } from 'react';
import { CardContainer, CustomDivider, ThumbnailIcon } from './style';
import { Destination } from 'types/Destination';
import { Col, Flex, Row } from 'antd';
import { TextM, TextS } from 'components/Text';
import { normalizeBankAccount, normalizeCpfCnpj } from 'helpers/normalizers';
import { useTheme } from 'styled-components';
import { BankListCode } from 'constants/bankListCode';
import { FaArrowRight } from 'react-icons/fa6';
import { truncateString } from 'helpers/formaters';
import { PixNormalizers } from 'modules/escrow/utils/normalizePix';

export type DestinationCardInfoType = 'button' | 'info';

export interface DestinationCardInfoProps {
	data: Destination;
	type?: DestinationCardInfoType;
	onClick?: (data: Destination) => void;
}

const DestinationCardInfo = ({
	data,
	type = 'button',
	onClick,
}: DestinationCardInfoProps) => {
	const theme = useTheme();

	const textStyles = {
		fontSize: '12px',
		color: theme.textGray,
		fontWeight: 400,
	};

	const handleClick = useCallback(() => {
		onClick?.(data);
	}, [data]);

	return (
		<CardContainer onClick={handleClick} type={type}>
			<Row gutter={[16, 16]}>
				<Col span={3}>
					<ThumbnailIcon id="thumbnail-icon" type={type}>
						<TextM>{data?.name?.[0] || '-'}</TextM>
					</ThumbnailIcon>
				</Col>
				<Col span={18}>
					<Row>
						<TextM
							style={{
								color: theme.textGray,
							}}
						>
							{truncateString(data?.name || '', 40)}
						</TextM>
					</Row>
					{data?.key != null && (
						<Row>
							<TextM
								style={{
									...textStyles,
									color: theme.textGray,
								}}
							>
								Chave Pix:{' '}
								{truncateString(
									PixNormalizers[
										data.key_type as keyof typeof PixNormalizers
									](data?.key ?? ''),
									40,
								)}
							</TextM>
						</Row>
					)}

					{type === 'info' && (
						<Row>
							<TextS style={textStyles}>
								CPF / CNPJ:{' '}
								{normalizeCpfCnpj(data?.taxpayer_id || '')}
							</TextS>
						</Row>
					)}
					<Row>
						<Flex align="center">
							<TextS style={textStyles}>
								Banco:{' '}
								{data?.bank
									? (BankListCode[
											data.bank as keyof typeof BankListCode
										] ?? data.bank)
									: '-'}
							</TextS>

							<CustomDivider type="vertical" />

							<TextS style={textStyles}>
								Agência: {data?.branch || '-'}
							</TextS>

							<CustomDivider type="vertical" />

							<TextS style={textStyles}>
								Conta:{' '}
								{normalizeBankAccount(data?.account) || '-'}
							</TextS>
						</Flex>
					</Row>
				</Col>
				{type === 'button' && (
					<Col span={3}>
						<Flex
							align="center"
							style={{
								height: '100%',
							}}
						>
							<FaArrowRight
								id="arrow-right"
								size={20}
								style={{ color: theme.primary }}
							/>
						</Flex>
					</Col>
				)}
			</Row>
		</CardContainer>
	);
};

export default DestinationCardInfo;
