import React, { useEffect, useState } from 'react';
import {
	Button,
	Col,
	Form,
	Input,
	InputNumber,
	notification,
	Row,
	Select,
	Spin,
} from 'antd';
import { Destination, DestinationType } from 'types/Destination';
import { normalizeBankAccount, normalizeCpfCnpj } from 'helpers/normalizers';
import { serializeOnlyNumbers } from 'helpers/serializers';
import {
	cpfCnpjRule,
	emailRule,
	fullNameRule,
	phoneRule,
	uuidV4Rule,
} from 'helpers/rules';
import { BankListOptions } from 'constants/banksList';
import { DestinationTypeDescription } from 'constants/destination';
import { useTheme } from 'styled-components';
import { TextM, TextS } from 'components/Text';
import { ForwardButton } from './components/ForwardButton';
import { ApiError } from 'types/ApiError';
import { Account } from 'types/Account';
import { useDestinations } from 'modules/escrow/hooks';
import { queryClient } from 'network/query';
import { StepsWrapper } from './styles';
import DestinationReview from '../DestinationCreationModal/DestinationReview';
import PixKeySelect, { PixKey } from 'components/PixKeySelect/PixKeySelect';
import { useQuery } from '@tanstack/react-query';
import { AccountsService } from 'modules/escrow/services';
import {
	getPixNormalizer,
	normalizePixKeyOut,
} from 'modules/escrow/utils/normalizePix';

export interface DestinationCreationProps {
	isLoading?: boolean;
	account: Account;
	onCreate: (newDestinationId: string) => void;
	onBack: () => void;
}

enum Steps {
	FORM = 0,
	CONFIRMATION = 1,
}

const DestinationCreation = ({
	account,
	isLoading,
	onCreate,
	onBack,
}: DestinationCreationProps) => {
	const theme = useTheme();
	const [api, contextHolder] = notification.useNotification();
	const [form] = Form.useForm();
	const [currentStep, setCurrentStep] = useState<Steps>(Steps.FORM);
	const [selectedDestination, setSelectedDestination] = useState<
		Destination | undefined
	>();
	const [selectedPixKey, setSelectedPixKey] = useState<PixKey>(
		PixKey.TAXPAYER_ID,
	);

	const {
		data: pixDictData,
		isLoading: pixDictIsLoading,
		isFetching,
		error,
		refetch,
	} = useQuery({
		queryKey: ['getPixDict', account.id],
		queryFn: () =>
			AccountsService.getDict(account?.id!, {
				key: normalizePixKeyOut(
					selectedDestination?.key || '',
					selectedDestination?.key_type?.includes('CPF') ||
						selectedDestination?.key_type?.includes('CNPJ')
						? PixKey.TAXPAYER_ID
						: (selectedDestination?.key_type as PixKey),
				),
				key_type: selectedDestination?.key_type || '',
				key_owner_taxpayer_id: selectedDestination?.taxpayer_id || '',
			}),
		enabled: false,
		// !!selectedDestination?.key && currentStep === Steps.CONFIRMATION,
		refetchOnWindowFocus: false,
		retry: false,
	});

	const { createOrUpdateDestination, loading } = useDestinations(account.id, {
		onCreateSuccess: async ({ id }) => {
			//HK: faz o refetch da query de accountData para atualizar a lista de contas beneficiárias após a criação de uma nova
			// colocar dados da conta em contexto
			await queryClient.refetchQueries({
				queryKey: ['accountData', account.id],
			});

			await queryClient.refetchQueries({
				queryKey: ['accountDetails', account.id],
			});

			api.success({
				message: 'Conta beneficiária cadastrada com sucesso',
			});

			onCreate(id);
		},
		onError: (error: ApiError) => {
			api.error({
				message: error.data.message,
				description:
					'Ocorreu um problema ao cadastrar a conta beneficiária',
			});
			form.resetFields();
			setCurrentStep(Steps.FORM);
		},
	});

	const getDestination = (value: Destination) => {
		return {
			taxpayer_id:
				selectedPixKey !== PixKey.BANK_ACCOUNT
					? pixDictData?.key_owner_taxpayer_id || ''
					: value?.taxpayer_id || '',
			name: value?.name || '',
			bank:
				selectedPixKey !== PixKey.BANK_ACCOUNT
					? pixDictData?.bank || ''
					: value?.bank || '',
			branch:
				selectedPixKey !== PixKey.BANK_ACCOUNT
					? pixDictData?.branch || ''
					: value?.branch || '',
			account:
				selectedPixKey !== PixKey.BANK_ACCOUNT
					? pixDictData?.account || ''
					: value?.account || '',
			type:
				selectedPixKey !== PixKey.BANK_ACCOUNT
					? pixDictData?.account_type || DestinationType.CACC
					: value?.type || DestinationType.CACC,
			id: value?.id || '',
			key:
				selectedPixKey !== PixKey.BANK_ACCOUNT
					? pixDictData?.key
					: value?.key || '',
			key_type:
				selectedPixKey !== PixKey.BANK_ACCOUNT
					? pixDictData?.key_type
					: value?.key_type || '',
		};
	};

	useEffect(() => {
		if (error) {
			const errorData = error as unknown as { data: { message: string } };
			api.error({
				description: errorData?.data?.message,
				message: 'Ocorreu um problema ao buscar informações do Pix.',
			});

			queryClient.resetQueries({
				queryKey: ['getPixDict', account.id],
			});

			setCurrentStep(Steps.FORM);
		}
	}, [error]);

	useEffect(() => {
		if (
			currentStep === Steps.CONFIRMATION &&
			selectedPixKey !== PixKey.BANK_ACCOUNT
		) {
			refetch();
		}
	}, [currentStep]);

	return (
		<>
			{contextHolder}
			<StepsWrapper>
				<>
					{isLoading || isFetching ? (
						<Row
							justify="center"
							style={{
								minHeight: '200px',
								flexDirection: 'column',
							}}
						>
							<Spin />
							<TextM
								color={theme.primary}
								style={{
									marginTop: '2rem',
									textAlign: 'center',
								}}
							>
								Carregando informações...
							</TextM>
						</Row>
					) : (
						<Form<Destination>
							style={{ width: '100%' }}
							initialValues={selectedDestination}
							layout="vertical"
							form={form}
							onFinish={values => {
								if (currentStep === Steps.FORM) {
									const destination = {
										...values,
										id: selectedDestination?.id || '',
										taxpayer_id: serializeOnlyNumbers(
											values.taxpayer_id,
										),
										account: serializeOnlyNumbers(
											values.account,
										),
										bank: values.bank || '',
										branch: values.branch?.toString(),
										key: values.key,
										key_type:
											selectedPixKey ===
											PixKey.TAXPAYER_ID
												? values.key?.length === 11
													? 'CPF'
													: 'CNPJ'
												: selectedPixKey ===
													  PixKey.BANK_ACCOUNT
													? undefined
													: selectedPixKey,
									};
									setSelectedDestination(destination);
									setCurrentStep(Steps.CONFIRMATION);
								}

								if (currentStep === Steps.CONFIRMATION) {
									createOrUpdateDestination({
										...getDestination(selectedDestination!),
										id_end_to_end:
											pixDictData?.id_end_to_end,
										...(selectedPixKey ===
											PixKey.BANK_ACCOUNT && {
											key: undefined,
											key_type: undefined,
											id_end_to_end: undefined,
										}),
									});
									setSelectedDestination(undefined);
									form.resetFields();
								}
							}}
						>
							<>
								{currentStep === Steps.CONFIRMATION && (
									<DestinationReview
										data={{
											institutionName:
												getDestination(
													selectedDestination!,
												).bank || '-',
											beneficiaryName:
												getDestination(
													selectedDestination!,
												).name || '-',
											beneficiaryTaxpayerId:
												getDestination(
													selectedDestination!,
												).taxpayer_id || '-',
											key:
												getDestination(
													selectedDestination!,
												).key || '-',
											keyType:
												getDestination(
													selectedDestination!,
												).key_type || '-',
											bankBranchNumber:
												getDestination(
													selectedDestination!,
												).branch || '-',
											bankAccountNumber:
												getDestination(
													selectedDestination!,
												).account || '-',
										}}
									/>
								)}

								{currentStep === Steps.FORM && (
									<>
										<Row gutter={[16, 16]}>
											<Col span={24}>
												<Form.Item
													name="taxpayer_id"
													label="Identificação do beneficiário"
													normalize={value =>
														normalizeCpfCnpj(value)
													}
													rules={[
														{
															required: true,
															message:
																'Campo obrigatório',
														},
														cpfCnpjRule,
													]}
												>
													<Input
														placeholder="Digite o CPF ou CNPJ"
														onChange={e => {
															form.setFieldsValue(
																{
																	[e?.target
																		?.name ||
																	'']:
																		e.target
																			.value,
																},
															);
															if (
																selectedPixKey ===
																PixKey.TAXPAYER_ID
															) {
																form.setFieldsValue(
																	{
																		key: normalizeCpfCnpj(
																			e
																				.target
																				.value,
																		),
																	},
																);
															}
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={[16, 16]}>
											<Col span={24}>
												<Form.Item
													name="name"
													label="Nome do beneficiário"
													rules={[
														{
															required: true,
															message:
																'Campo obrigatório',
														},
														{
															max: 50,
															message:
																'Máximo de 50 caracteres',
														},
														fullNameRule('name'),
													]}
												>
													<Input placeholder="Ex.: João da Silva" />
												</Form.Item>
											</Col>
										</Row>

										<Row
											gutter={[16, 16]}
											style={{ margin: '1rem 0' }}
										>
											<PixKeySelect
												value={selectedPixKey}
												onChange={selected => {
													setSelectedPixKey(selected);
													form.setFieldsValue({
														key:
															selected ===
															PixKey.TAXPAYER_ID
																? form.getFieldValue(
																		'taxpayer_id',
																	) != null
																	? form.getFieldValue(
																			'taxpayer_id',
																		)
																	: ''
																: '',
													});
												}}
											/>
										</Row>
										{selectedPixKey !==
											PixKey.BANK_ACCOUNT && (
											<Row gutter={[16, 16]}>
												<Col span={24}>
													<Form.Item
														name="key"
														label="Chave Pix"
														normalize={value => {
															return getPixNormalizer(
																selectedPixKey,
															)(value);
														}}
														rules={[
															{
																required: true,
																message:
																	'Campo obrigatório',
															},
															...(selectedPixKey ===
															PixKey.TAXPAYER_ID
																? [cpfCnpjRule]
																: []),
															...(selectedPixKey ===
															PixKey.EMAIL
																? [
																		emailRule(
																			'key',
																		),
																	]
																: []),
															...(selectedPixKey ===
															PixKey.PHONE
																? [
																		phoneRule(
																			'key',
																		),
																	]
																: []),
															...(selectedPixKey ===
															PixKey.EVP
																? [uuidV4Rule]
																: []),
														]}
													>
														<Input
															placeholder="Digite a chave Pix"
															disabled={
																selectedPixKey ===
																PixKey.TAXPAYER_ID
															}
														/>
													</Form.Item>
												</Col>
											</Row>
										)}

										{selectedPixKey ===
											PixKey.BANK_ACCOUNT && (
											<>
												<Row gutter={[16, 16]}>
													<Col span={24}>
														<Form.Item
															name="bank"
															label="Banco"
															rules={[
																{
																	required:
																		true,
																	message:
																		'Campo obrigatório',
																},
															]}
														>
															<Select
																showSearch={
																	true
																}
																filterOption={(
																	input,
																	option,
																) =>
																	!!option?.label
																		?.toLowerCase()
																		?.includes(
																			input.toLowerCase(),
																		)
																}
																placeholder="Selecione"
																options={
																	BankListOptions
																}
															/>
														</Form.Item>
													</Col>
												</Row>
												<Row gutter={[16, 16]}>
													<Col span={12}>
														<Form.Item
															name="branch"
															label="Agência (sem dígito)"
															rules={[
																{
																	required:
																		true,
																	message:
																		'Campo obrigatório',
																},
															]}
														>
															<InputNumber
																placeholder="0000"
																min={0}
															/>
														</Form.Item>
													</Col>
													<Col span={12}>
														<Form.Item
															name="account"
															label="Conta"
															normalize={value =>
																normalizeBankAccount(
																	value,
																)
															}
															rules={[
																{
																	required:
																		true,
																	message:
																		'Campo obrigatório',
																},
															]}
														>
															<Input
																placeholder="000000-0"
																min={0}
															/>
														</Form.Item>
													</Col>
												</Row>
												<Row gutter={[16, 16]}>
													<Col span={24}>
														<Form.Item
															name="type"
															label="Tipo de conta"
															rules={[
																{
																	required:
																		true,
																	message:
																		'Campo obrigatório',
																},
															]}
														>
															<Select
																placeholder="Selecione"
																options={Object.keys(
																	DestinationTypeDescription,
																).map(key => ({
																	label: DestinationTypeDescription[
																		key as DestinationType
																	],
																	value: key,
																}))}
															/>
														</Form.Item>
													</Col>
												</Row>
											</>
										)}
									</>
								)}
							</>
						</Form>
					)}
				</>
				<div>
					<Row
						style={{
							width: '100%',
							marginTop: '1rem',
						}}
					>
						<ForwardButton
							isLoading={
								loading || isFetching || pixDictIsLoading
							}
							disabled={loading || isFetching || pixDictIsLoading}
							onClick={form.submit}
							text="Avançar"
						/>
					</Row>
					<Row
						style={{
							marginTop: '1rem',
						}}
					>
						<Button
							type="default"
							loading={isLoading}
							onClick={() => {
								onBack();
							}}
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								border: 'none',
								boxShadow: 'none',
								color: theme.primary,
								width: '100%',
							}}
						>
							<TextS
								style={{
									color: theme.primary,
								}}
							>
								Voltar
							</TextS>
						</Button>
					</Row>
				</div>
			</StepsWrapper>
		</>
	);
};

export default DestinationCreation;
