import { DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
	CustomRangePickerContainer,
	RangePickerFixedText,
	RangePickerSeparator,
} from './styles';
import { BsCalendar } from 'react-icons/bs';

export interface CustomRangePickerProps {
	handleDateChange: (
		dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null,
	) => void;
	value?: [dayjs.Dayjs | null, dayjs.Dayjs | null];
	maxDate?: dayjs.Dayjs;
	minDate?: dayjs.Dayjs;
}

const { RangePicker } = DatePicker;

const CustomRangePicker = ({
	value,
	maxDate,
	minDate,
	handleDateChange,
}: CustomRangePickerProps) => {
	const [maxDateState, setMaxDateState] = useState<dayjs.Dayjs | undefined>(
		maxDate || undefined,
	);
	const handleChange = (
		dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null,
	) => {
		if (!dates || !dates[0] || !dates[1]) {
			setMaxDateState(dayjs());
		}
		handleDateChange(dates);
	};

	return (
		<CustomRangePickerContainer>
			<BsCalendar />
			<RangePickerFixedText>De</RangePickerFixedText>
			<RangePicker
				suffixIcon={null}
				variant="borderless"
				format="DD/MM/YYYY"
				onChange={handleChange}
				separator={<RangePickerSeparator>a</RangePickerSeparator>}
				maxDate={
					maxDateState && maxDateState >= dayjs()
						? dayjs()
						: maxDateState
				}
				minDate={minDate}
				onCalendarChange={dates => {
					//TODO: colocar prop para configurar data maxima
					if (dates && dates?.[0]) {
						const newMaxDate = dates[0].add(1, 'month');
						setMaxDateState(newMaxDate);
					}
				}}
			/>
		</CustomRangePickerContainer>
	);
};

export default CustomRangePicker;
