import { useEffect, useState } from 'react';
import { Button, notification } from 'antd';
import { PageWrapper } from 'components/PageWrapper';
import { PageHeader } from 'components/PageHeader';
import { Heading1 } from 'components/Heading1';
import { useBreadcrumb } from 'modules/core/context/AppContext';
import { BreadcrumbContainer } from 'components/Breadcrumb';
import { UsersIcon } from 'modules/users/constants/routes';
import { UserCreation } from 'components/UserCreation';
import { PageActions } from 'components/PageActions';
import { UsersListTable } from 'components/UsersListTable';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessType } from 'types/Access';
import { Navigate, useNavigate } from 'react-router-dom';
import { LuPlusCircle } from 'react-icons/lu';
import { UserListContent } from 'types/User';
import { UserBlocking } from 'components/UserBlocking';
import { InformationModal } from 'components/InformationModal';
import { CheckmarkIcon } from 'components/Icons/Checkmark';
import { useMutation } from '@tanstack/react-query';
import { UsersService } from 'modules/users/services';
import { ApiError } from 'types/ApiError';
import { ConfirmationResetUserAccessModal } from 'components/ConfirmationResetUserAccessModal';

const UsersListAdmin = () => {
	const { setBreadcrumb } = useBreadcrumb();
	const { person: company, type } = useCurrentProfile();
	const [api, contextHolder] = notification.useNotification();
	const navigate = useNavigate();
	const [isUserCreationOpen, setIsUserCreationOpen] = useState(false);
	const [isUserCreationSuccessOpen, setIsUserCreationSuccessOpen] =
		useState(false);
	const [isUserBlockingOpen, setIsUserBlockingOpen] = useState<
		UserListContent | false
	>(false);
	const [userAccessToReset, setUserAccessToReset] =
		useState<UserListContent | null>(null);
	const [isUserAccessResetSuccessOpen, setIsUserAccessResetSuccessOpen] =
		useState(false);

	useEffect(() => {
		setBreadcrumb([
			{
				href: '/company',
				title: (
					<BreadcrumbContainer>
						<UsersIcon />
						<span>Acessos</span>
					</BreadcrumbContainer>
				),
			},
		]);
	}, [setBreadcrumb]);

	const { mutate, isPending, error } = useMutation<
		void,
		ApiError,
		{ id: string; emailAddress: string; mfaToken: string }
	>({
		mutationFn: ({ id, emailAddress, mfaToken }) =>
			UsersService.udpateUser(id, emailAddress, mfaToken),
		onSuccess: () => {
			setUserAccessToReset(null);
			setIsUserAccessResetSuccessOpen(true);
		},
		onError: error => {
			api.error({
				message: 'Erro ao resetar acesso de usuário.',
				description: error?.data?.message || '',
			});
			setUserAccessToReset(null);
		},
	});

	if (type !== AccessType.FINANCIAL_INSTITUTION) {
		return <Navigate to="/" />;
	}

	return (
		<>
			{contextHolder}
			<PageHeader>
				<Heading1>Acessos</Heading1>
				<PageActions>
					<Button
						type="primary"
						onClick={() => setIsUserCreationOpen(true)}
					>
						Criar Usuário
						<LuPlusCircle
							style={{ marginLeft: '0.6rem' }}
							size={18}
						/>
					</Button>
				</PageActions>
			</PageHeader>
			<PageWrapper>
				<UsersListTable
					onView={user => navigate(`/users/${user.id}`)}
					onBlock={user => setIsUserBlockingOpen(user)}
					onResetAccess={user => setUserAccessToReset(user)}
				/>
			</PageWrapper>
			<UserBlocking
				isOpen={!!isUserBlockingOpen}
				user={isUserBlockingOpen}
				onClose={() => {
					setIsUserBlockingOpen(false);
				}}
				onError={() => {
					setIsUserBlockingOpen(false);
					api.error({
						message: 'Erro ao bloquear usuário.',
					});
				}}
			/>
			<UserCreation
				isOpen={isUserCreationOpen}
				companyId={company?.id}
				onClose={() => {
					setIsUserCreationOpen(false);
				}}
				onSuccess={() => {
					setIsUserCreationOpen(false);
					setIsUserCreationSuccessOpen(true);
				}}
			/>

			<ConfirmationResetUserAccessModal
				title="Resetar Acesso"
				userData={{
					email_address: userAccessToReset?.email_address || '',
					full_name: userAccessToReset?.full_name || '',
					id: userAccessToReset?.id || '',
					taxpayer_id: userAccessToReset?.taxpayer_id || '',
				}}
				isOpen={!!userAccessToReset}
				onClose={() => setUserAccessToReset(null)}
				onConfirm={(user, mfaToken) =>
					mutate({
						id: user!.id,
						emailAddress: user!.email_address,
						mfaToken,
					})
				}
				isLoading={isPending}
			/>

			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={isUserCreationSuccessOpen}
				title="Acesso criado com sucesso!"
				icon={<CheckmarkIcon />}
				message="Acesso criado com sucesso!"
				onClose={() => setIsUserCreationSuccessOpen(false)}
			/>

			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={isUserAccessResetSuccessOpen}
				title="Acesso resetado com sucesso!"
				icon={<CheckmarkIcon />}
				message="Acesso resetado com sucesso!"
				onClose={() => setIsUserAccessResetSuccessOpen(false)}
			/>
		</>
	);
};

export default UsersListAdmin;
