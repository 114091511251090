import { NetworkApi } from 'network';
import { DestinationDto } from 'types/Destination';

export class DestinationsService extends NetworkApi {
	public static createDestination(
		accountId: string,
		destination: DestinationDto,
	) {
		return this.post<{ id: string }, DestinationDto, void>(
			`/accounts/${accountId}/destinations`,
			destination,
		);
	}

	public static updateDestination(
		accountId: string,
		destinationId: string,
		destination: Partial<DestinationDto>,
	) {
		return this.patch<void, Partial<DestinationDto>, void>(
			`/accounts/${accountId}/destinations/${destinationId}`,
			destination,
		);
	}

	public static deleteDestination(accountId: string, destinationId: string) {
		return this.delete<void>(
			`/accounts/${accountId}/destinations/${destinationId}`,
		);
	}
}
