import React, { useEffect } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import {
	LoginForgotPassword,
	LoginFormContainer,
	LoginFormSubmit,
} from './styles';
import { LoginFormType } from './types';
import { useAuthContext } from 'modules/core/context/AuthContext';
import { CognitoErrorCode } from 'modules/core/types/Cognito';
import { useForm } from 'antd/lib/form/Form';

const LoginForm: React.FC = () => {
	const [form] = useForm();
	const { login, resetError, errorCode, isLoading } = useAuthContext();

	useEffect(() => {
		if (errorCode === CognitoErrorCode.LOGIN_FAILED) {
			form.validateFields();
		}
	}, [errorCode, form]);

	return (
		<LoginFormContainer
			form={form}
			style={{ width: '90%' }}
			layout="vertical"
			onValuesChange={resetError}
			onFinish={(values: LoginFormType) => {
				login(values.username, values.password);
			}}
		>
			<Form.Item
				name="username"
				label="Usuário:"
				rules={[
					{ required: true, message: 'Campo obrigatório' },
					() => ({
						validator() {
							if (errorCode === CognitoErrorCode.LOGIN_FAILED) {
								return Promise.reject(
									new Error('Usuário ou senha inválidos'),
								);
							}
							return Promise.resolve();
						},
					}),
				]}
			>
				<Input
					prefix={<UserOutlined />}
					placeholder="exemplo@email.com"
				/>
			</Form.Item>
			<Form.Item
				name="password"
				label="Senha:"
				rules={[
					{ required: true, message: 'Campo obrigatório' },
					() => ({
						validator() {
							if (errorCode === CognitoErrorCode.LOGIN_FAILED) {
								return Promise.reject(
									new Error('Usuário ou senha inválidos'),
								);
							}
							return Promise.resolve();
						},
					}),
				]}
			>
				<Input
					prefix={<LockOutlined />}
					type="password"
					placeholder="Senha"
				/>
			</Form.Item>
			<LoginForgotPassword to="/forgot">
				Esqueci minha senha
			</LoginForgotPassword>
			<LoginFormSubmit
				loading={isLoading}
				type="primary"
				htmlType="submit"
			>
				Entrar
			</LoginFormSubmit>
		</LoginFormContainer>
	);
};

export default LoginForm;
